export class HorarioDetalleDto {
    secureId: string;
    id: number;
    horaInicio: Date;
    horaPausa: Date;
    horaInicio2: Date;
    horaFin: Date;
    taxistaId: number;
    diaSemanaId: number;

    constructor() {
        this.id = -1;
        this.diaSemanaId= -1;
        this.horaInicio= null;
        this.horaPausa= null;
        this.horaInicio2= null;
        this.horaFin= null;
    }
}