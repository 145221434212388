
export class Notificacio {

    secureId: string;
    id: number;
    idFrom: number;
    secureIdFrom: string;
    idNot: number;
    idTrip: number;
    minutsTard: number;
    mensaje: string;
}