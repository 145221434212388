import { Component, OnInit } from '@angular/core';
import { ContextMenuPopover } from '../../models/context-menu-popover.model';
import { NavParams } from '@ionic/angular';
import { DisplayService } from 'src/app/services';

@Component({
  selector: 'app-context-menu-popover',
  templateUrl: './context-menu-popover.component.html',
  styleUrls: ['./context-menu-popover.component.scss'],
})
export class ContextMenuPopoverComponent implements OnInit {

  public data: ContextMenuPopover;

  constructor(
    private navParams: NavParams,
    private displayService: DisplayService
  ) { }

  ngOnInit() {
    this.data = this.navParams.data as ContextMenuPopover;
  }

  click( command: () => void ) {
    command();
    this.displayService.hidePopover();
  }

}
