import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';
import { UserAbstract, Notificacio } from '../models';
import { NotificacioDto } from '../models/notificacioDto.model';
import { NotificacionTaxista } from '../models/notificacionTaxista.model';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
 
  private user: UserAbstract;
  constructor(
    private http: HttpClientService,
    private userService: UserService  ) {
    this.user = this.userService.getUser();
     }


  solNotTrip( not : Notificacio ): Observable<any> {
    not.secureIdFrom = this.user.secure_id;
    return this.http.post( EndpointsApi.solLateUserTrip, not, not).pipe( map( res => {
      return res;
    }));
  }

  sendNotifToUser( not : NotificacionTaxista ): Observable<any> {
    return this.http.post( EndpointsApi.sendNotifToUser, not, not).pipe( map( res => {
      return res;
    }));
  }

  sendNotifToTaxi( not : Notificacio ): Observable<any> {
    not.secureIdFrom = this.user.secure_id;
    return this.http.post( EndpointsApi.sendNotifToTaxi, not, not).pipe( map( res => {
      return res;
    }));
  }

  getNotificacions(): Observable<any> {
    const secureId = this.user.secure_id;
    return this.http.getWithUrlParams(EndpointsApi.getNotificacionsByUser.replace('{secureId}', secureId)).pipe( map( res => {
      return res;
    }));
  }

  updateNoti(noti: NotificacioDto): Observable<any> {
    return this.http.post(EndpointsApi.updateNoti, noti).pipe( map( res => {
      return res;
    }));
  }

}
