import { UserAbstract, UserKind, UserAbstractInterface } from './user-abstract.model';
import { Vehicle } from './vehicle.model';
import { HorarioDetalleDto } from './horario-detalleDto.model';

export interface UserTaxiInterface extends UserAbstractInterface {
    start?: string;
    finish?: string;
    start2?: string;
    finish2?: string;
    workDays?: string;
    vehicles?: Vehicle[];

}

export class UserTaxi extends UserAbstract implements UserTaxiInterface {

    start: string;
    finish: string;
    start2: string;
    finish2: string;
    workDays: string;
    vehicles: Vehicle[];
    horariosDia: HorarioDetalleDto[];
    institution: number;

    constructor( init: UserTaxiInterface, vehicles: Vehicle[], horarios: HorarioDetalleDto[] ) {
        super( init );
        this.kind = UserKind.TAXI;
        Object.assign( this, init );
        this.vehicles = vehicles==null ? [] : vehicles;
        this.horariosDia = horarios;
    }

}