import { HttpParams } from '@angular/common/http';
import { EndpointsApi } from './../config/endpoints';
import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { UserAbstract, TrayectoLiquidacion } from 'src/app/models';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { map } from 'rxjs/internal/operators';
import { TaxiSettlingsFilter } from '../pages/taxi-tabs/taxi-settlings/taxi-setlings-filter/taxi-settlings-filter';

@Injectable({
    providedIn: 'root'
})
export class TaxiSettlingsService {

    private user: UserAbstract;
    constructor(
        private userService: UserService,
        private http: HttpClientService
    ) {
        this.user = this.userService.getUser();
    }

    getTaxiSettlings(filter: TaxiSettlingsFilter): Observable<Array<TrayectoLiquidacion>> {
        const params = this.createCustomParams(filter);
        return this.http.get(EndpointsApi.trayectosLiquidaciones, params).pipe( map(res => {
            return res;
        }));
    }

    createCustomParams(filter: TaxiSettlingsFilter) {
        const obj = {
            fechaInicio : filter.fechaInicio !== undefined ? filter.fechaInicio.toJSON() : null,
            fechaFin : filter.fechaFin !== undefined ? filter.fechaFin.toJSON() : null,
            secureIdtaxista : this.user.secure_id
        };
        return obj;
    }
}
