import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { WeekDay } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public readonly FORMAT_DATE = 'YYYY-MM-DD';
  public readonly FORMAT_DATE_WITHOUT_SPACES = 'YYYYMMDD';
  public readonly FORMAT_DATETIME = 'YYYY-MM-DDTHH:mm:ss';

  // Todo: modificar en funcio de locale.

  private daysOfWeek: WeekDay[] = [
    { idx: 0, day: 'Dilluns', short: 'Dl' },
    { idx: 1, day: 'Dimarts', short: 'Dm' },
    { idx: 2, day: 'Dimecres', short: 'Dc' },
    { idx: 3, day: 'Dijous', short: 'Dj' },
    { idx: 4, day: 'Divendres', short: 'Dv' },
    { idx: 5, day: 'Dissabte', short: 'Ds' },
    { idx: 6, day: 'Diumenge', short: 'Dg' }
  ];

  constructor() { }

  public getCurrentYear(): number {
    return new Date().getFullYear();
  }

  public getFormatedDate( date: string, format: string ): string {
    if ( !date || date === '' ) {
        return '';
    } else {
        return moment(date).format(format);
    }
  }

  public getDateTime(): string {
    return moment(new Date()).format(this.FORMAT_DATETIME);
  }

  public getDayOfWeek( idx: number ): string {
    return this.daysOfWeek.find( e => e.idx === idx ).day;
  }

  getDaysOfWeek(): WeekDay[] {
    return this.daysOfWeek;
  }
}
