import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DisplayService, NotificationsService } from 'src/app/services';
import { Notificacio } from 'src/app/models/notificacio.model';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-late-user-modal',
  templateUrl: './late-user-modal.component.html',
  styleUrls: ['./late-user-modal.component.scss'],
})
export class LateUserModalComponent implements OnInit {

  public form: FormGroup;
  public minutsTard: number;
  public tripId: number;
  public mensaje: string;
  public not = new Notificacio();

  constructor(
    private notificationService: NotificationsService,
    private navParams: NavParams,
    private displayService: DisplayService
  ) {
    this.minutsTard = 0;

    this.not.secureId = '-1|-1';
    this.not.idNot = 1;
  }

  ngOnInit() {
    this.tripId = this.navParams.data.tripId;
  }

  close(): void {
    this.displayService.hideModal();
  }

  solLateTrip(): void {
    this.not.idTrip = this.tripId;
    this.not.mensaje = this.mensaje;
    this.notificationService.solNotTrip( this.not ).subscribe(
      () => {
        this.displayService.hideModal();
        this.displayService.showToast('Incidència enviada correctament');
      }
    );
  }

  onSelectMinuts(event: any) {
    const dateAux = new Date(event.detail.value);
    this.not.minutsTard = dateAux.getMinutes();
  }
}
