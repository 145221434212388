import { Component, OnInit } from '@angular/core';
import { NavParams, NavController, ModalController, PopoverController } from '@ionic/angular';
import { RouterPopover } from '../../models';
import { DisplayService } from '../../services';

@Component({
  selector: 'app-router-popover',
  templateUrl: './router-popover.component.html',
  styleUrls: ['./router-popover.component.scss'],
})
export class RouterPopoverComponent implements OnInit {

  public data: RouterPopover;

  constructor(
    private navParams: NavParams,
    private navController: NavController,
    private displayService: DisplayService
  ) { }

  ngOnInit() {
    this.data = this.navParams.data as any;
  }

  goToRoute(route: string): void {
    this.navController.navigateRoot(route, { animated: true });
    this.displayService.hidePopover();
  }

}
