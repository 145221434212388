import { Injectable } from '@angular/core';
import { Faq, ServicioDetalleTaxistaDto, UserAbstract, TrayectoTaxistaDetalle } from '../models';
import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';
import { UserService } from './user.service';
import { ServicioTaxistaDto } from '../models/servicio-taxistaDto.model';
import { DisplayService } from './display.service';
import { AdicionalPassangerOnService } from '../models/adicional-passanger-on-service.model';
import { SimpleTaxista } from '../models/simpleTaxista.model';

@Injectable({
  providedIn: 'root'
})
export class TaxiTripService {
  private scannedCode: string;

  private user: UserAbstract;
  constructor(
    private userService: UserService,
    private http: HttpClientService,
    private displayService: DisplayService,
    ) {
      this.user = this.userService.getUser();
  }

  getTripsOfUser(): Observable<ServicioTaxistaDto[]> {
    const userId = this.user.secure_id;
    return this.http.getWithUrlParams( EndpointsApi.trayectosTaxistas.replace('{secureId}', userId)).pipe( map( res => {
      return res; // as ServicioDetalleTaxistaDto[];
    }));
  }

  acceptTrip( trip: ServicioTaxistaDto ): Observable<void> {
    return this.http.post( EndpointsApi.acceptarTrayectoTaxista, trip).pipe( map( res => {
      return res;
    }));
  }

  acceptDelegation( trip: ServicioTaxistaDto ): Observable<void> {
    return this.http.post( EndpointsApi.acceptarDelegacionTrayectoTaxista, trip).pipe( map( res => {
      return res;
    }));
  }

  declineTrip( trip: ServicioTaxistaDto ): Observable<void> {
    return this.http.post( EndpointsApi.DeclineTrayectosTaxistas, trip).pipe( map( res => {
      return res;
    }));
  }

  declineDelegation( trip: ServicioTaxistaDto ): Observable<void> {
    return this.http.post( EndpointsApi.DeclineDelegacionTrayectosTaxistas, trip).pipe( map( res => {
      return res;
    }));
  }
  startTrip( trip: ServicioTaxistaDto ): Observable<void> {
    return this.http.post( EndpointsApi.iniciarTrayectoTaxista, trip).pipe( map( res => {
      return res;
    }));
  }
  endTrip( trip: ServicioTaxistaDto ): Observable<void> {
    let isPosibleFinatzar = true;
    for(const serv of trip.serviciosTrayecto) {
       if(!serv.userSolicitante.validado){
        let isPosibleFinatzar = false;
       }
    }
    if(!isPosibleFinatzar){
      this.displayService.showToast('Per a finalitzar projecte cal afegir tots usuaris');
      return;
    }

    return this.http.post( EndpointsApi.finalizarTrayectoTaxista, trip).pipe( map( res => {
      return res;
    }));
  }
  declineAcceptedTrip( trip: ServicioTaxistaDto ): Observable<void> { // TODO [somintec] - Connectar WS
    return this.http.post( EndpointsApi.DeclineAcceptedTrayectosTaxistas, trip).pipe( map( res => {
      return res;
    }));
  }

  validarSolicitanteDni( dni: string, trip: ServicioDetalleTaxistaDto ): Observable<void> { // TODO [somintec] - Connectar WS
    const values = {
      dni: dni,
      secureIdRuta: trip.secureIdRuta,
      secureIdUsuario: trip.userSolicitante.secureId,
      passajero: false
    }
    return this.http.post( EndpointsApi.validarPassatgerDni, values).pipe( map( res => {
      return res;
    }));
  }

  validarPassatgerDni( dni: string, trip: ServicioDetalleTaxistaDto ): Observable<void> { // TODO [somintec] - Connectar WS
    const values = {
      dni: dni,
      secureIdRuta: trip.secureIdRuta,
      secureIdUsuario: '',
      passajero: true
    };

    return this.http.post( EndpointsApi.validarPassatgerDni, values).pipe( map( res => {
      return res;
    }));
  }

  validarMenorSenseDni(trip: ServicioDetalleTaxistaDto): Observable<void> {
    const values = {
      dni: '',
      secureIdRuta: trip.secureIdRuta,
      secureIdUsuario: '',
      passajero: true
    };

    return this.http.post( EndpointsApi.validarPassatgerMenorSenseDni, values).pipe( map( res => {
      return res;
    }));
  }

  deleteSolicitantOnTrip( solicitant: any): Observable<any> {
    return this.http.post( EndpointsApi.deleteSolicitantOnService, solicitant).pipe( map( res => {
      return res;
    }));
  }

  deletePassangerOnTrip( passajero: any): Observable<any> {
    return this.http.post( EndpointsApi.deletePassengerOnService, passajero).pipe( map( res => {
      return res;
    }));
  }

  getAllTrips(): Observable<TrayectoTaxistaDetalle[]> {
    return this.http.get(EndpointsApi.allTrayectosTaxistas).pipe( map( res => {
      return res;
    }));
  }

  AddPassangerOnService(passanger: AdicionalPassangerOnService) {
    return this.http.post( EndpointsApi.addPassangerOnService, passanger).pipe( map( res => {
      return res;
    }));
  }

  setScannedCode(code: string) {
   this.scannedCode = code;
  }

  getScannedCode(): string {
    return this.scannedCode;
  }

  getTaxistas(secureId: string): Observable<SimpleTaxista[]> {
    return this.http.getWithUrlParams( EndpointsApi.getTaxistas.replace('{secureId}', secureId)).pipe( map( res => {
      return res;
    }));
  }
  delegarServicio(secureIdServicio: string, secureIdTaxista: string): Observable<any> {
    return this.http.getWithUrlParams( EndpointsApi.propuestaDelegarServicio.replace('{secureIdServicio}', secureIdServicio).replace('{secureIdTaxista}',secureIdTaxista)).pipe( map( res => {
      return res;
    }));
  }
}
