import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/internal/operators';
import { EndpointsApi } from '../config/endpoints';
import { environment } from 'src/environments/environment';
import * as querystring from 'querystring';



@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  private baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { 
    
  }




  protected getParams( params: any = {} ): HttpParams {
    return new HttpParams({
      fromObject: params
    });
  }

  public get( route: EndpointsApi, params?: any ): Observable<any> {
    const options = {
      params: this.getParams( params )
    };

    return this.http.get( this.baseUrl + route, options );
  }

  public getWithUrlParams( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.get( this.baseUrl + route, options );

  }

  public delete( route: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.delete( this.baseUrl + route, options );

  }

  public post( route: string, body: any, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.post( this.baseUrl + route, body , options );

  }

  public loginPost( route: string, body: any ): Observable<any> {
    const bodyString = querystring.stringify(body);
    return this.http.post( this.baseUrl + route, bodyString );
  };

  public put( route: string, body: any, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.put( this.baseUrl + route, body, options );

  }

  public path( route: string, body: string, params?: any ): Observable<any> {

    const options = {
      params: this.getParams( params )
    };

    return this.http.patch( this.baseUrl + route, body, options );

  }
}
