// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  UserService,
  TabsService,
  CityService,
  DisplayService,
  DateService,
  FaqService,
  TaxiTripService,
  NotificationsService,
  StorageService,
  HttpClientService,
  VehicleService,
  GeolocationService,
  PlatformService,
  LocaleService
} from '.';

// Services

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    UserService,
    TabsService,
    CityService,
    DisplayService,
    TaxiTripService,
    DateService,
    FaqService,
    NotificationsService,
    StorageService,
    HttpClientService,
    StorageService,
    VehicleService,
    GeolocationService,
    PlatformService,
    LocaleService
  ]
})
export class ServicesModule { }
