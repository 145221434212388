import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    try {

      const d = new Date(value);
      return d.getHours().toString().padStart( 2, '0' ) + ':' + d.getMinutes().toString().padStart( 2, '0' );

    } catch (ex) {
      return 'Invalid date.';
    }


  }

}
