import { Component, ViewChildren, QueryList, OnDestroy } from '@angular/core';

import { Platform, IonRouterOutlet, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user.service';
import { StorageService } from './services/storage.service';
import { Router } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { DisplayService } from './services';
import { AlertButton } from '@ionic/core';
import { StorageKeys } from './config/storage';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  [x: string]: any;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  lastTimeBackPress: number;
  timePeriodToExit: number;
  isInitBackButtonEvent = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storageService: StorageService,
    private router: Router,
    private alertController: AlertController,
    private firebase: FirebaseX,
    private user: UserService,
    private displayService: DisplayService,
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  ngOnDestroy(): void {
    console.log('On destroy');
  }

  ionViewWillLeave(){
    console.log('ionViewWillLeave app.component')
    this.user.logout();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await this.storageService.getStoredObjects();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      document.addEventListener('backbutton', () => {
        console.log('backbutton');
        this.backButtonEvent();
      });
      this.isMobile = this.platform.is('android') || this.platform.is('ios');
      if (this.isMobile) {
        this.initPush();
      }
    });
  }

    // active hardware back button

  backButtonEvent() {
    console.log( this.router.url);
    if (!this.isInitBackButtonEvent) {
      this.presentAlertConfirm();
    }
    this.isInitBackButtonEvent = false;
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      message: 'Esteu segur que voleu sortir de l`aplicació?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: 'Tancar App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });

    await alert.present();
  }

  initPush() {
    this.firebase.grantPermission().then((hasPermission) => {
      
        // Show some message to the user telling that 
        // your app will open the settings and then...
        // this.diagnosticPlugin.switchToSettings();
    
    });
   
  
    this.firebase.onMessageReceived().subscribe(data => {
      if (data.wasTapped) {
        // res
        // alert(JSON.stringify(data.body));
        // this.alertNotificacio('Notificació', data.body, 'D\'acord');
      } else {
        // alert(JSON.stringify(data.body));
         this.alertNotificacio('Notificació', data.body, 'D\'acord');
      }
    });
  }

  alertNotificacio(title: string, message: string, buttonLabel: string) {
    const buttons: AlertButton[] = [
      { text: buttonLabel, handler: data => true }
    ];
    this.displayService.showAlert(title, message, null, buttons);
  }
}
