import { UserResumen } from './user-resumen.model';

export class RutaTrayectoTaxistaDetalle {

    public secureId: string;
    public paradaOrigen: string;
    public paradaDestino: string;
    public userSolicitante: UserResumen;
    // public List<UsuarioAcompananteTableDto> acompanantes { get; set; }
}

