export enum EndpointsApi {
    // Faqs
    getFaqs = '/app/faqs',

    // City
    getCities = '/app/municipios',
    getCitiesInstitution = '/app/municipios/{secureId}',
    getLegalText = '/app/GetLegalText',
    getFilePrivacy = '/app/GetPrivacyFile',
    getMensajesMunicipio = '/app/GetMensajesMunicipio/{id}/{idInstitucion}',

    // Institution
    getInstitutions = '/app/instituciones',

    login = '/token',
    getMenores = '/app/GetMenores/{secureId}',
    registerUser = '/app/registrarUsuario',
    updateUser = '/app/updateUsuario',
    deleteAccount ='/app/deleteAccount',
    updateTaxi = '/app/updateTaxista',
    getUser = '/app/GetUsuario/{secureId}',
    getUserApp = '/app/GetUsuarioApp/{secureId}/{institutionId}',
    changePwd = '/app/changePwd',
    resetPwd = '/app/resetPwd',
    setFreeDays = '/app/setFreeDays',
    getFreeDays = '/app/getFreeDays/{secureId}',
    getVehicles = '/app/getVehicles/{secureId}',

    // services
    getServices = '/app/serviciosDisponibles',
    getServicesDisponibles = '/app/serviciosDisponiblesFecha',

    // services user
    getTripsOfUser = '/app/trayectos/trayectosUsuario',
    solCancelUserTrip= '/app/trayectos',
    solCancelUserSolicitud= '/app/trayectos/delete',
    solLateUserTrip= '/app/notificacionsUsarioTarde',
    getServicesStartingPoints = '/app/GetParadasServicio/{secureId}',
    getTripsEndOfUser  = '/app/GetParadasDestinoServicio',
    getPriceService = '/app/GetPriceService',
    getHoursService = '/app/GetHoursService',
    solicitateTrip = '/app/SolicitarTrayecto',
    SetFavTrayecto = '/app/SetFavTrayecto',
    GetServicioFavorito = '/app/GetServicioFavorito',
    GetTriptic = '/app/GetTriptico/{secureId}',
    GetListTriptic = '/app/GetListTriptico/{secureId}',

    //Notificaciones
    getNotificacionsByUser = '/app/GetNotificacionesByUsuario/{secureId}',
    updateNoti = '/app/UpdateNotificacion',
    sendNotifToUser = '/app/SendNotifToUser',
    sendNotifToTaxi = '/app/SendNotifToTaxi',

    //servicios taxista
    trayectosTaxistas = '/app/TrayectosTaxistas/{secureId}',
    allTrayectosTaxistas = '/app/AllTrayectosTaxistas',
    acceptarTrayectoTaxista = '/app/AcceptTrayectosTaxistas',
    iniciarTrayectoTaxista = '/app/StartTrayectosTaxistas',
    finalizarTrayectoTaxista = '/app/EndTrayectosTaxistas',
    DeclineTrayectosTaxistas = '/app/DeclineTrayectosTaxistas',
    DeclineAcceptedTrayectosTaxistas = '/app/DeclineAcceptedTrayectosTaxistas',
    validarPassatgerDni = '/app/ValidarPassajero',
    validarPassatgerMenorSenseDni = '/app/ValidarPassajeroMenorSinDni',
    deletePassengerOnService = '/app/DeletePassengerOnService',
    deleteSolicitantOnService = '/app/DeleteSolicitantOnService',
    horariosTaxistas = '/app/HorariosTaxistas/{secureId}',
    trayectosLiquidaciones = '/app/trayectos/liquidacion',
    addPassangerOnService = '/app/AddPassangerOnService',
    getTaxistas = '/app/getTaxistas/{secureId}',
    propuestaDelegarServicio = '/app/propuestaDelegarServicio/{secureIdServicio}/{secureIdTaxista}',
    acceptarDelegacionTrayectoTaxista = '/app/AcceptDelegacionTrayectosTaxistas',
    DeclineDelegacionTrayectosTaxistas = '/app/DeclineDelegacionTrayectosTaxistas',

    //caracteristicas taxi y trayecto
    GetCaracteristicasTaxi = '/app/taxis/caracteristicas',
    GetCaracteristicasTrayecto = '/app/trayectos/caracteristicas'
}
