import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { City } from 'src/app/models';
import { CityService, DisplayService, StorageService, UserService } from 'src/app/services';
import { Platform } from '@ionic/angular';
import { StorageKeys } from 'src/app/config/storage';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-municipio-modal',
  templateUrl: './select-municipio-modal.component.html',
  styleUrls: ['./select-municipio-modal.component.scss'],
})
export class SelectMunicipioModalComponent implements OnInit {

  municipiosSource: BehaviorSubject<Array<City>>;
  public form: FormGroup;

  isCurrentCity = false;
  currentCity: City;

  constructor(
    private userService: UserService,
    private cityService: CityService,
    private displayService: DisplayService,
    private platform: Platform,
    private storageService: StorageService
  ) {
    this.municipiosSource = new BehaviorSubject(new Array<City>());
  }

  /* #region  COMPONENT LIFECYCLE */

  ngOnInit() {
    this.checkMunicipioDefined();
    this.getCities();

    this.form = new FormGroup({
      municipio: new FormControl( '', Validators.required )
    });
  }

  /* #endregion */

  /* #region  EVENTS */

  municipioSeleccionado(event: any): void {
    const municipio = event.detail.value;
    this.currentCity = this.municipiosSource.value.find(m => m.id === municipio);
    if (municipio !== undefined && municipio !== null) {
      this.userService.setMunicipio(this.currentCity.id);
      this.storageService.get(StorageKeys.institution).then(institutionId => {
        const cityNew = new City(this.currentCity.secureId, this.currentCity.id, this.currentCity.nombreOficial, institutionId);
        this.storageService.set(StorageKeys.city, cityNew);
        this.cityService.setCurrentCity(cityNew);
        this.isCurrentCity = true;
      });
    }
  }

  close(): void {
    this.displayService.hideModal();
  }

  /* #endregion */

  /* #region  DATA GETTERS && SETTERS */

  getCities(): void {
    this.displayService.showLoading();
    this.cityService.getCities().subscribe(result => {
      this.municipiosSource.next(result);
      this.displayService.hideLoading();
    }, error => {
      this.displayService.hideLoading();
    });
  }

  /* #endregion */

  /* #region  OTHERS */

  checkMunicipioDefined(): void {
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.storageService.get('LoginCity' as StorageKeys).then(cityId => {
        if (this.currentCity === undefined) {
          this.storageService.get(StorageKeys.institution).then(institutionId => {
            this.currentCity = new City(undefined, cityId, undefined, institutionId);
          });
        }
        this.currentCity.id = cityId;
      }).catch(error => {
        this.currentCity = undefined;
      });
    }
    this.isCurrentCity = this.currentCity !== undefined;
  }

  /* #endregion */
}

