import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayService } from '../../../services/display.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Service, StoppingPoint, RouteSchedule, User, UserType } from 'src/app/models';
import { UserTripsService } from '../../../services/user-trips.service';
import { DateService } from '../../../services/date.service';
import { NavParams, ModalController,NavController } from '@ionic/angular';
import { UserService } from '../../../services/user.service';
import { SolicitudTrayectoDto } from 'src/app/models/solicitud-trayecto.model';

import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { AddPassengerComponent } from '../add-passenger/add-passenger.component';
import { AdicionalPassanger } from 'src/app/models/adicional-passanger.model';
import { AllTaxiServicesPage } from 'src/app/pages/taxi-tabs/all-taxi-services/all-taxi-services.page';
import { CaracteristicasTaxi } from 'src/app/models/caracteristicas-taxi.model';
import { CaracteristicasTrayecto } from 'src/app/models/caracteristicas-trayecto.model';
import { UserAllServicesFilter } from 'src/app/pages/user-tabs/user-all-services/user-all-services-filter/user-all-services-filter';

@Component({
  selector: 'app-add-user-service',
  templateUrl: './add-user-service.component.html',
  styleUrls: ['./add-user-service.component.scss'],
})
export class AddUserServiceComponent implements OnInit {

  public form: FormGroup;
  public user: User;
  public userTypes: UserType[];
  public services: Service[];
  public startingPoints: StoppingPoint[];
  public stoppingPoints: StoppingPoint[];
  public serviceSchedules: RouteSchedule[];
  public addicionalPassengers: AdicionalPassanger[] = [];
  public servicePrice: string;
  public userPrice: string;
  public slectedDate: Date;

  public selectedService: Service;
  private selectedStartingPoint: StoppingPoint;
  public selectedStoppingPoint: StoppingPoint;
  private selectedScheduleService: RouteSchedule;
  public seleccionHora: Date;
  public fechaInicio: string;
  public fechaFin: string;
  private promisecount: number;

  public caracteristicasTaxi: CaracteristicasTaxi[];
  public caracteristicasTrayecto: CaracteristicasTrayecto[];

  public menorSenseDni: string = 'Menor sense dni';

 //config calendar
 myDate = this.slectedDate;
 disabledDates: Date[] = [
       new Date(1545911005644),
       new Date(),
       new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.
       new Date('Wednesday, December 26, 2018'), // Works with any valid Date formats like long format
       new Date('12-14-2018'), // Short format
 ];
 datePickerObj = {
   inputDate: new Date(), // default new Date()
   fromDate: null, // default null
   toDate: null, // default null
   showTodayButton: false, // default true
   closeOnSelect: true, // default false
   disableWeekDays: [], // default []
   mondayFirst: true, // default false
   setLabel: 'S',  // default 'Set'
   todayLabel: 'T', // default 'Today'
   closeLabel: 'Tancar', // default 'Close'
   disabledDates: [], // default []
   titleLabel: 'Select a Date', // default null
   monthsList: ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Septembre", "Octubre", "Novembre", "Desembre"],
   weeksList: [ "Dg","Dll", "Dm", "Dc", "Dj", "Dv", "Ds"],
   dateFormat: 'DD-MM-YYYY', // default DD MMM YYYY
   clearButton : false , // default true
   momentLocale: 'es-ES', // Default 'en-US'
   yearInAscending: true, // Default false
   btnCloseSetInReverse: true, // Default false
   btnProperties: {
     expand: 'block', // Default 'block'
     fill: '', // Default 'solid'
     size: '', // Default 'default'
     disabled: '', // Default false
     strong: '', // Default false
     color: '' // Default ''
   },
   arrowNextPrev: null/*{
     nextArrowSrc: 'assets/images/arrow_right.svg',
     prevArrowSrc: 'assets/images/arrow_left.svg'
   }*/, // This object supports only SVG files.
   highlightedDates: [], // Default [],
   isSundayHighlighted : {
    fontColor: '#ee88bf' // Default null
   } // Default {}
 };
 //end config calendar

 filter: UserAllServicesFilter;

  constructor(
    private displayService: DisplayService,
    private userTripService: UserTripsService,
    private userService: UserService,
    public dateService: DateService,
    private navParams: NavParams,
    public modalCtrl: ModalController,
    public navController: NavController
  ) {
    this.filter = new UserAllServicesFilter();
  }

  ngOnInit() {
    this.selectedService = this.navParams.data.service;
    this.filter = this.navParams.data.filter;
    this.initLoading();
    this.createForm();
    this.user = this.userService.getUser() as User
    Promise.all([
      this.getServices(),
      //this.getUserTypes(),
      this.getCaracteristicasTaxi(),
      this.getCaracteristicasTrayecto()
    ]).then(value => {
      console.log("all promise");
      this.displayService.hideLoading();
    });
  }

  initLoading() {
    this.displayService.showLoading("add-user-service initLoading");
    this.promisecount = 0;
  }
  promiseCounter(){
    this.promisecount ++;
    console.log(this.promisecount);
    if (this.promisecount >= 2){
      //this.displayService.hideLoading();
      this.promisecount = 0;
    }
  }

  close(): void {
    this.displayService.hideModal();
  }

  createForm(): void {
    if (this.selectedService.horarioFijo) {
      this.form = new FormGroup({
        service: new FormControl( '', Validators.required ),
        origin: new FormControl( '', Validators.required ),
        end: new FormControl( '', Validators.required ),
        hour: new FormControl( '', Validators.required  ),
        fecha : new FormControl( '', Validators.required  ),
        dependants: new FormControl(),
        acompanantes: new FormControl(),
        types: new FormControl(),
        caracteristicasTaxi: new FormControl(),
        caracteristicasTrayecto: new FormControl()
      });
    } else {
      this.form = new FormGroup({
        service: new FormControl( '', Validators.required ),
        origin: new FormControl( '', Validators.required ),
        end: new FormControl( '', Validators.required ),
        hourSelected: new FormControl( '', Validators.required ),
        fecha : new FormControl( '', Validators.required  ),
        dependants: new FormControl(),
        acompanantes: new FormControl(),
        types: new FormControl(),
        caracteristicasTaxi: new FormControl(),
        caracteristicasTrayecto: new FormControl()
      });
    }
    this.promiseCounter();
  }

  getServices(): Promise<any> {
    return new Promise((resolve) => {
      this.userTripService.getServicesDetall(this.filter).subscribe(
            res => {
              this.services = res;
              resolve();
              if ( this.selectedService ) {
                this.form.reset( { service: this.selectedService.id } );
                this.getStartsOfService();
              }
              this.promiseCounter();
            }, err => {
              resolve();
            }
          );
    });
  }

  // getUserTypes(): Promise<any> {
  //return this.user.type;
    //   return new Promise((resolve) => {
  //       this.userService.getUserTypes().subscribe(
  //         res => {
  //           resolve();
  //           this.userTypes = res;//.filter( i => this.user.type.includes( i.id ) );
  //           this.promiseCounter();
  //         }
  //       );
  //   });
  // }

  getCaracteristicasTaxi(): Promise<any> {
    return new Promise((resolve) => {
      this.userTripService.getCaracteristicasTaxi().subscribe(
            res => {
              resolve();
              this.caracteristicasTaxi = res;
              this.promiseCounter();
            }, err => {
              resolve();
            }
          );
    });
  }

  getCaracteristicasTrayecto(): Promise<any> {
    return new Promise((resolve) => {
      this.userTripService.getCaracteristicasTrayecto().subscribe(
            res => {
              resolve();
              this.caracteristicasTrayecto = res;
              this.promiseCounter();
            }, err => {
              resolve();
            }
          );
    });
  }

  onSelectService( e: any ): void {
    const serviceId = e.detail.value;
    if ( !this.selectedService || ( this.selectedService.id !== serviceId ) ) {
      this.selectedService = this.services.find( element => element.id === serviceId );
      this.fechaInicio = this.parseDate(this.selectedService.fechaInicio);
      this.fechaFin = this.parseDate(this.selectedService.fechaFin);
      this.getStartsOfService();
      this.selectedStoppingPoint = null;
      this.stoppingPoints =null;
    }
  }

  onSelectOrigin( e: any ): void {
    const startingPointId = e.detail.value;
    if ( !this.selectedStartingPoint || ( this.selectedStartingPoint.id !== startingPointId ) ) {
      this.selectedStartingPoint = this.startingPoints.find( el => el.id === startingPointId );
      this.getStopsOfService();
      if(this.selectedStoppingPoint !== undefined && this.selectedStoppingPoint !== null &&  
        this.selectedStoppingPoint.secureId !== undefined && this.selectedStoppingPoint.secureId !== null){
          this.getServiceSchedules();
        }
    }
  }

  onSelectEnd( e: any ): void {
    const stoppingPointId = e.detail.value;
    if ( !this.selectedStoppingPoint || ( this.selectedStoppingPoint.id !== stoppingPointId ) ) {
      this.selectedStoppingPoint = this.stoppingPoints.find( el => el.id === stoppingPointId );
      this.getServiceSchedules();
    }
  }
  onSelectServiceSchedule( e: any): void {
    const scheduledServiceId = e.detail.value;
    if ( !this.selectedScheduleService || ( this.selectedScheduleService.secureId !== scheduledServiceId ) ) {
      this.selectedScheduleService = this.serviceSchedules.find( el => el.secureId === scheduledServiceId );
    }
  }

  getStartsOfService(): void {
    this.userTripService.getServiceStartingPoints( this.selectedService.secureId ).subscribe(
      res => {
        this.startingPoints = res;
      }
    );
  }

  getStopsOfService(): void {
    this.userTripService.getServiceEndPoints( this.selectedService.secureId, this.selectedStartingPoint.secureIdZona ).subscribe(
      res => {
        this.stoppingPoints = res;
      }
    );
  }

  getServiceSchedules(): void {
    if(this.selectedService.horarioFijo){
       this.displayService.showLoading("add-user-service getServiceSchedules");
       const solicitud: SolicitudTrayectoDto = new SolicitudTrayectoDto(this.selectedService.secureId,
          this.selectedStartingPoint.secureId,
          this.selectedStoppingPoint.secureId,
          '',
          null,
          this.user.secure_id,
          '',
          '',
          null,
          null,
          null,
          [],
          [],
          [],
          []);
       this.userTripService.getServiceHoursAvailables(solicitud).subscribe(res => {
            this.serviceSchedules = res;
            this.displayService.hideLoading();
          }
        );
      }else{
        this.seleccionHora = new Date();
      }
  }

  async addPassenger() {
    let passanger: AdicionalPassanger;
    const passangerPickerModal = await this.modalCtrl.create({
      component: AddPassengerComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: { passatger: passanger }
    });
    await passangerPickerModal.present();

    passangerPickerModal.onDidDismiss()
      .then((data) => {
        passanger = data['data'];
        if (passanger !== undefined && passanger !== null) {
          this.addicionalPassengers.push( passanger );
        }
      });
  }

  removePassenger(): void {
    this.addicionalPassengers.pop();
  }

  getServicePrice() {
    const sol = this.form.value;
    sol.addicionalPassengers = this.addicionalPassengers;
    let solicitud: SolicitudTrayectoDto = this.createObject();
    if(this.form.value.dependants !== undefined && this.form.value.dependants !== null){
      this.form.value.dependants.forEach(element => {
      solicitud.securesIdsMenors.push(element);
      });
    }

    /*if(this.form.value.addicionalPassengers!== undefined && this.form.value.addicionalPassengers !== null){
        this.form.value.addicionalPassengers.forEach(element => {
        solicitud.acomapanantesDoc.push(element);
        });
    }*/
    this.displayService.showLoading("add-user-service getServicePrice");
    this.userTripService.getServicePrice( solicitud ).subscribe(
      res => {
        this.userPrice = res.precioUsuarioIva;
        this.servicePrice = (res.precioServicioIva ).toFixed(2) + ' €';
        this.displayService.hideLoading();
      }
    );
  }
  createObject(): SolicitudTrayectoDto {
    if(this.selectedService.horarioFijo){
      return   new SolicitudTrayectoDto(this.selectedService.secureId,
        this.selectedStartingPoint.secureId,
        this.selectedStoppingPoint.secureId,
        this.selectedScheduleService.secureId,
        null,
        this.user.secure_id,
        '',
        '',
        this.slectedDate,
        null,
        null,
        [],
        this.addicionalPassengers,
        [],
        []);
    }else{
      return    new SolicitudTrayectoDto(this.selectedService.secureId,
        this.selectedStartingPoint.secureId,
        this.selectedStoppingPoint.secureId,
        '',
        this.seleccionHora,
        this.user.secure_id,
        '',
        '',
        this.slectedDate,
        null,
        null,
        [],
        this.addicionalPassengers,
        [],
        []);
    }
  }

  editSol() {
    delete this.userPrice;
    delete this.servicePrice;
  }

  solService() {
    const sol = this.form.value;
    sol.addicionalPassengers = this.addicionalPassengers;
    const solicitud = this.createObject();

    if (this.form.value.dependants !== undefined && this.form.value.dependants !== null){
        this.form.value.dependants.forEach(element => {
          solicitud.securesIdsMenors.push(element);
        });
    }

    if (this.form.value.caracteristicasTaxi !== undefined && this.form.value.caracteristicasTaxi !== null){
      this.form.value.caracteristicasTaxi.forEach(element => {
        solicitud.securesIdsCaracteristicasTaxi.push(element);
      });
    }

    if (this.form.value.caracteristicasTrayecto !== undefined && this.form.value.caracteristicasTrayecto !== null){
      this.form.value.caracteristicasTrayecto.forEach(element => {
        solicitud.securesIdsCaracteristicasTrayecto.push(element);
      });
    }

   /* if(this.form.value.addicionalPassengers!== undefined && this.form.value.addicionalPassengers !== null){
        this.form.value.addicionalPassengers.forEach(element => {
        solicitud.acomapanantesDoc.push(element);
        });
    }*/
    this.displayService.showLoading("add-user-service solService");
    this.userTripService.solService( solicitud ).subscribe(
      () => {
        this.displayService.hideModal();
        this.displayService.hideLoading();
        this.displayService.showToast('Servei sol·licitat correctament', 3000);
      }, err => {
        //
        this.displayService.hideModal();
        this.displayService.hideLoading();
        if(err.status === 406){
          this.displayService.showToastButton(err.text, true);
        }
      }
    );
  }

  parseDate(fecha: Date): string {
    return fecha.toString().substring(0, 10);
  }

  async openDatePicker() {
    this.datePickerObj.fromDate = this.selectedService.fechaInicio;
    this.datePickerObj.toDate = this.selectedService.fechaFin;
    if ( new Date() <= this.stringToDate(this.selectedService.fechaInicio)
        || (this.selectedService.fechaFin !== null && new Date() >= this.stringToDate(this.selectedService.fechaFin)) ) {
      this.datePickerObj.inputDate = this.stringToDate(this.selectedService.fechaInicio);
    }

    if (new Date().getTime() >= Date.parse(this.datePickerObj.fromDate)) {
      this.datePickerObj.fromDate = new Date();
    }

    // 0 Sunday to 6 Saturday (nosaltres enviem 0 Monday to 6 Sunday)
    this.datePickerObj.disableWeekDays = [];
    for ( let i = 0; i < 7; i++) {
      if (this.selectedService.diasSemanaActivo.indexOf(i) === -1) {
        if (i === 6) {
          this.datePickerObj.disableWeekDays.push(0);
        } else {
          this.datePickerObj.disableWeekDays.push(i + 1);
        }
      }
    }
    this.selectedService.festivosMunicipio.forEach(festivoMunicipio => {
      this.datePickerObj.disabledDates.push(festivoMunicipio.fechaFestivo);
    });
    this.selectedService.festivosServicio.forEach(festivoServicio => {
      this.datePickerObj.disabledDates.push(festivoServicio.fechaFestivo);
    });

    this.datePickerObj.highlightedDates = [
      { date: this.selectedService.fechaInicio, color: '#ee88bf', fontColor: '#fff' },
      { date: this.selectedService.fechaFin, color: '#ee88bf', fontColor: '#fff' }
    ];

    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: { 'objConfig': this.datePickerObj, 'selectedDate': this.slectedDate }
    });
    await datePickerModal.present();

    datePickerModal.onDidDismiss()
      .then((data) => {
        // this.isModalOpen = false;
        if (data !== undefined && data !== null && data.data.date !== 'Invalid date' ) {
          const dia = data.data.date.substring(0, 2);
          const mes = data.data.date.substring(3, 5);
          const year = data.data.date.substring(6, 10);

          //al fer new date el mes comença per 0
          this.slectedDate = new Date(year, mes - 1 , dia);
        }
      });
  }

  getLabelPerfils(): string{
    let perfiles = '';
    let firstTime = true;
    this.user.type.forEach(tipo => {
      if (firstTime) {
        firstTime = false;
        perfiles = tipo.nombre;
      } else {
        perfiles = perfiles + ', ' + tipo.nombre;
      }
    });

    return perfiles;
  }

  /* #region  OTHERS */

  ambDni(dni: string) {
    return dni !== undefined && dni !== null && dni !== '';
  }

  stringToDate(value: Date) {
    if (value !== null && !this.isDate (value)) {
      value = new Date(value);
    }
    return value;
  }

  isDate(value: Date) {
    return value instanceof Date;
  }

  /* #endregion */
}
