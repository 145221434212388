import { SelectMunicipioModalComponent } from './user/select-municipio-modal/select-municipio-modal.component';
/** Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

/** Modules */
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PipesModule } from '../pipes/pipes.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

/** Components */
import { RouterPopoverComponent } from './router-popover/router-popover.component';
import { ContextMenuPopoverComponent } from './context-menu-popover/context-menu-popover.component';
import { EditUserProfileModalComponent } from './user/edit-user-profile-modal/edit-user-profile-modal.component';
import { AddUserServiceComponent } from './user/add-user-service/add-user-service.component';
import { EditTaxiProfileModalComponent } from './taxi/edit-taxi-profile-modal/edit-taxi-profile-modal.component';
import { MapComponent } from './map/map.component';
import { DetailUserServiceComponent } from './user/detail-user-service/detail-user-service.component';
import { TripticUserServiceComponent } from './user/triptic-user-service/triptic-user-service.component';
import { DetailTaxiServiceComponent } from './taxi/detail-taxi-service/detail-taxi-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AddPassengerComponent } from './user/add-passenger/add-passenger.component';
import { LateUserModalComponent } from './user/late-user-modal/late-user-modal.component';
import {DelegateServiceComponent } from 'src/app/pages/taxi-tabs/taxi-services/delegate-service/delegate-service.component';
import { ExpandableCardViewComponent } from './expandable-card-view/expandable-card-view.component';
import { PassatgerDniModalComponent } from './taxi/passatger-dni-modal/passatger-dni-modal.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule,
    PipesModule,
    PdfViewerModule,
  ],
  declarations: [
    RouterPopoverComponent,
    ContextMenuPopoverComponent,
    EditUserProfileModalComponent,
    AddUserServiceComponent,
    EditTaxiProfileModalComponent,
    MapComponent,
    DetailUserServiceComponent,
    TripticUserServiceComponent,
    DetailTaxiServiceComponent,
    PrivacyPolicyComponent,
    AddPassengerComponent,
    LateUserModalComponent,
    DelegateServiceComponent,
    ExpandableCardViewComponent,
    SelectMunicipioModalComponent,
    PassatgerDniModalComponent
  ],
  exports: [
    RouterPopoverComponent,
    ContextMenuPopoverComponent,
    EditUserProfileModalComponent,
    AddUserServiceComponent,
    EditTaxiProfileModalComponent,
    MapComponent,
    DetailUserServiceComponent,
    TripticUserServiceComponent,
    DetailTaxiServiceComponent,
    PrivacyPolicyComponent,
    AddPassengerComponent,
    LateUserModalComponent,
    DelegateServiceComponent,
    ExpandableCardViewComponent,
    SelectMunicipioModalComponent,
    PassatgerDniModalComponent
  ],
  entryComponents: [
    RouterPopoverComponent,
    ContextMenuPopoverComponent,
    EditUserProfileModalComponent,
    AddUserServiceComponent,
    EditTaxiProfileModalComponent,
    MapComponent,
    DetailUserServiceComponent,
    TripticUserServiceComponent,
    DetailTaxiServiceComponent,
    PrivacyPolicyComponent,
    AddPassengerComponent,
    LateUserModalComponent,
    DelegateServiceComponent,
    ExpandableCardViewComponent,
    SelectMunicipioModalComponent,
    PassatgerDniModalComponent
  ]
})
export class ComponentsModule { }
