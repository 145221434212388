import { Injectable, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { Tab, UserKind } from '../models';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  isMobile: boolean = false;
  private tabs: Tab[] = [];
  constructor(
    private userService: UserService,
    private platform: Platform
  ) {

    this.isMobile = this.platform.is("android") || this.platform.is("ios");
    // User
    this.tabs.push({route: 'user-profile', icon: 'person', label: 'Profile', canAccess: [UserKind.USER]});
    this.tabs.push({ route: 'user-services', icon: 'calendar', label: 'Services', canAccess: [UserKind.USER]});
    this.tabs.push({ route: 'user-all-services', icon: 'car', label: 'All Services', canAccess: [UserKind.USER]});
    this.tabs.push({ route: 'user-notifications', icon: 'notifications', label: 'Notifications', canAccess: [UserKind.USER]});
    this.tabs.push({  route: 'user-faqs', icon: 'chatbubbles', label: 'Faqs', canAccess: [UserKind.USER] });
    this.tabs.push({  route: 'user-qrcode', icon: 'qr-scanner', label: 'QR-Codi', canAccess: [UserKind.USER] });

    // Taxi
    this.tabs.push({ route: 'taxi-profile', icon: 'person', label: 'Profile', canAccess: [UserKind.TAXI]});
    this.tabs.push({ route: 'taxi-services', icon: 'car', label: 'Services', canAccess: [UserKind.TAXI]});
    this.tabs.push({ route: 'all-taxi-services', icon: 'calendar', label: 'All Services', canAccess: [UserKind.TAXI]});
    this.tabs.push({ route: 'taxi-notifications', icon: 'notifications', label: 'Notifications', canAccess: [UserKind.TAXI]});
    this.tabs.push({ route: 'taxi-settlings', icon: 'card', label: 'Settlings', canAccess: [UserKind.TAXI] });

    // if (this.isMobile) {
    //   this.tabs.push({ route: 'taxi-qrscan', icon: 'qr-scanner', label: 'QrScanner', canAccess: [UserKind.TAXI]});
    // }

    // Common
    // this.tabs.push({ route: 'logout', icon: 'exit', label: 'Logout', canAccess: [UserKind.TAXI, UserKind.USER]});
  }

  getTabs(): Tab[] {
    const userType = this.userService.getUser().kind;
    return this.tabs.filter( e => e.canAccess.some( i => i === userType));
  }
}
