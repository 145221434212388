import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import {
  latLng,
  tileLayer,
  LatLng,
  Map,
  MapOptions,
   marker,
   icon,
   Marker,
   Layer,
   LatLngExpression,
   LeafletMouseEvent
  } from 'leaflet';
import { GeolocationService } from 'src/app/services';
import { DisplayService } from '../../services/display.service';
import { ContextMenuPopover } from '../../models/context-menu-popover.model';
import { ContextMenuPopoverComponent } from '../context-menu-popover/context-menu-popover.component';

export type markerColor = 'green' | 'grey' | 'red' | 'black' | 'blue' | 'orange' | 'yellow';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  public options: MapOptions;
  public zoom: number;
  public center: LatLng;

  public markers: Marker[] = [];

  private map: Map;
  private pendingLayers: Layer[] = [];
  private pendingBounds: [ number, number ][];
  public layers: Layer[] = [];

  @Input() height: string = '300px';
  @Output() mapClick: EventEmitter<{ latLng: {lat: number, lng: number}, originalEvent: Event }> = new EventEmitter();

  constructor(
    private geolocationService: GeolocationService,
    private displayService: DisplayService
  ) { }

  ngOnInit() {
    this.setMapOptions();
  }

  private setMapOptions(): void {
    this.zoom = 12;
    this.center = latLng([41.963892, 2.811811]);
    this.options = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
      ],
      zoom: this.zoom,
      center: this.center,
      attributionControl: false,
      zoomControl: false
    };
  }

  onMapReady(map: Map): void {
    // To prevent resize or render errors
    setTimeout(() => {
      this.map = map;
      map.invalidateSize();
      this.addPendingLayers();
      this.execPendingCenter();
      // this.getCurrentPosition();
    }, 300);
  }

  getCurrentPosition( addMarker?: boolean, center?: boolean ): void {
    this.geolocationService.getCurrentPosition().subscribe(
      res => {
        if ( center ) {
          this.setCenter( [ res.latitude, res.longitude ] );
        }
        if ( addMarker ) {
          this.addMarker( [ res.latitude, res.longitude ] );
        }
      }
    );
  }

  addMarker( latLon: LatLngExpression, color: markerColor = 'yellow'): void {
    const layer = marker(latLon, {
      icon: icon({
        iconSize: [18, 30],
        iconAnchor: [9, 30],
        iconUrl: 'assets/markers/marker-' + color + '.png',
        shadowUrl: 'assets/markers/marker-shadow.png',
      })
    });
    /*layer.on('click', ( e: LeafletMouseEvent ) => {
      this.showLayerContextMenu( e.originalEvent, layer );
    });*/

    if (this.map) {
      this.map.addLayer(layer);
    } else {
      this.pendingLayers.push(layer);
    }
    this.markers.push(layer);
  }

  private addPendingLayers(): void {
    const length = this.pendingLayers.length;
    for ( let i = 0; i < length; i++ ) {
      this.map.addLayer(this.pendingLayers.pop());
    }
  }

  setCenter( latlon: LatLngExpression ): void {
    if ( this.map ) {
      this.map.setView( latlon, 12 );
    }
  }

  private showLayerContextMenu( e: Event, layer: Layer ): void {
    const opt: ContextMenuPopover = {
      options: [
        {label: 'Delete', icon: 'trash', command: () => {
          this.map.removeLayer( layer );
          this.markers = this.markers.filter( i => i !== layer );
        } }
      ]
    };

    this.displayService.showPopover(ContextMenuPopoverComponent, e, opt);
  }

  public centerMarkers(): void {
    const bounds: [ number, number ][] = [];
    this.markers.forEach( e => bounds.push( [ e.getLatLng().lat, e.getLatLng().lng ] ) );
    if (bounds.length > 0) {
      if (this.map) {
        this.map.fitBounds( bounds );
      } else {
        this.pendingBounds = bounds;
      }
    }
  }

  private execPendingCenter(): void {
    if ( this.pendingBounds ) {
      this.map.fitBounds( this.pendingBounds );
    }
  }

  _mapClick( e: LeafletMouseEvent ) {
    this.mapClick.emit(
      {
        latLng: {
          lat: e.latlng.lat,
          lng: e.latlng.lng
        },
        originalEvent: e.originalEvent
      }
    );
  }

}
