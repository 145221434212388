import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CityService } from './city.service';
import { UserService } from './user.service';
import { catchError, map } from 'rxjs/internal/operators';
import { DisplayService } from './display.service';
import { NavController } from '@ionic/angular';
import { EndpointsApi } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private cityService: CityService,
    private displayService: DisplayService,
    private navController: NavController
  ) { }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const user = this.userService.getUser();
    const city = this.cityService.getCurrencCity();


    const headers: any = {
      'Content-Type': 'application/json'
    };

    if ( city && !req.url.includes( EndpointsApi.login ) ) {
      headers.Municipio = city.id !== undefined ? city.id.toString() : '';
      headers.Institucion = city.institucionFk !== undefined ? city.institucionFk.toString() : '';
    }

    if ( user && !req.url.includes( EndpointsApi.login ) ) {
      headers.Authorization = 'Bearer ' + user.token;
    }

    const request = req.clone({
      setHeaders: JSON.parse(JSON.stringify(headers))
    });

    return next.handle( request ).pipe(
      map( r => this.mapResponse(r) ),
      catchError( e => this.handleError(e) )
    );

  }

  handleError( error: any ) {

    let nextPage: string;
    const result: { [k: string]: any } = {};
    result.status = error.status;

    switch ( error.status ) {
      case 400: // Bad req
        break;
      case 401: // Unautorized
        nextPage = '/logout';
        break;
      case 403: // Forbidden
        break;
      case 500: // Server error
        break;
      default:
        break;
    }

    if ( typeof error.error === 'string' ) {
      result.text = error.error;
    } else if ( typeof error.message === 'string' ) {
      result.text = error.message;
    }

    //this.displayService.hideLoading();

    //this.displayService.showErrorToast(error.message);

    if ( nextPage ) {
      this.navController.navigateRoot( nextPage );
    }

    return throwError( result );

  }

  mapResponse( res: HttpEvent<any> ): any {
    //this.displayService.hideLoading();
    return res || {};
  }
}
