export enum UserKind {
    TAXI = 'taxi-tabs',
    USER = 'user-tabs'
}

export interface UserAbstractInterface {
    name?: string;
    surnames?: string;
    email: string;
    dni?: string;
    city?: number;
    token?: string;
    tokenExpiration?: Date;
    password?: string;
    plataform?: string;
    device_id?: string;
    secure_id: string;
    institution?: number;
}

export abstract class UserAbstract implements UserAbstractInterface {

    name: string;
    surnames: string;
    email: string;
    kind: UserKind;
    dni: string;
    city?: number;
    token: string;
    password: string;
    tokenExpiration?: Date;
    institution?: number;
    secure_id: string;

    constructor(init: UserAbstractInterface) {
        Object.assign(this, init);
    }
}
