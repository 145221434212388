import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  currentLocale: any;

  constructor(
  ) { }
}
