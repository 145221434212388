import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavParams, ModalController } from '@ionic/angular';
import { ServicioTaxistaDto } from 'src/app/models/servicio-taxistaDto.model';

@Component({
  selector: 'app-passatger-dni-modal',
  templateUrl: './passatger-dni-modal.component.html',
  styleUrls: ['./passatger-dni-modal.component.scss'],
})
export class PassatgerDniModalComponent implements OnInit {

  public form: FormGroup;
  public secureIdRuta: string;
  public trips: ServicioTaxistaDto;
  public menorSenseDni: boolean;
  public dni: string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {
  }

  ngOnInit() {
    this.secureIdRuta = this.navParams.data.secureIdRuta;
    this.trips = this.navParams.data.trips;
    this.menorSenseDni = false;
    this.dni = '';

    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({});
  }

  close() {
    this.modalController.dismiss();
  }

  addPassatgerDni() {
    this.dismissModal();
  }

  dismissModal() {
    if (this.form.valid) {
      const data: any = {
        menorSenseDni: this.menorSenseDni,
        dni: this.dni
      };

      this.modalController.dismiss( data );
    }
  }

}
