import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserTaxi } from '../../../models/user-taxi.model';
import { DisplayService } from 'src/app/services';
import { UserService } from '../../../services/user.service';
import { CityService } from '../../../services/city.service';
import { Events } from '@ionic/angular';
import { HorarioDetalleDto } from 'src/app/models/horario-detalleDto.model';

@Component({
  selector: 'app-edit-taxi-profile-modal',
  templateUrl: './edit-taxi-profile-modal.component.html',
  styleUrls: ['./edit-taxi-profile-modal.component.scss'],
})
export class EditTaxiProfileModalComponent implements OnInit {

  public form: FormGroup;
  public cities: any[];
  private user: UserTaxi = this.userService.getUser() as UserTaxi;
  public horarios: HorarioDetalleDto[];
  public horarioSelected: HorarioDetalleDto;
  public dia: number;

  constructor(
    private displayService: DisplayService,
    private userService: UserService,
    private cityService: CityService,
    public events: Events
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCities();
    this.getHorarios();
    this.horarioSelected = new HorarioDetalleDto();

  }

  createForm(): void {
    this.user.start = null;
    this.user.start2 = null;
    this.user.finish = null;
    this.user.finish2 = null;
    this.form = new FormGroup({
      name: new FormControl( this.user.name, Validators.required ),
      surnames: new FormControl( this.user.surnames, Validators.required ),
      dni: new FormControl( this.user.dni, Validators.required ),
      //city: new FormControl( this.user.city, Validators.required ),
      start: new FormControl( this.user.start, Validators.required ),
      finish: new FormControl( this.user.finish, Validators.required ),
      start2: new FormControl( this.user.start2, Validators.required ),
      finish2: new FormControl( this.user.finish2, Validators.required ),
      workDays: new FormControl( this.user.workDays, Validators.required ),
    });
    this.form.controls.workDays.setValue(null);
  }
  updateStartDate(event) {
    const startDate = this.stringToDate(this.form.value.start);    
    let error = false;
    if (this.form.value.finish !== undefined && this.form.value.finish !== null ) {

      const endDate = this.stringToDate(this.form.value.finish);
      if ( startDate > endDate ) {
        this.form.controls.start.setValue(null);
        error = true;
      }
    }
    if (this.form.value.start2 !== undefined && this.form.value.start2 !== null ) {
      const start2Date = this.stringToDate(this.form.value.start2);
      if ( startDate > start2Date ) {
        this.form.controls.start.setValue(null);
        error = true;
      }
    }
    if (this.form.value.finish2 !== undefined && this.form.value.finish2 !== null ) {
      const end2Date = this.stringToDate(this.form.value.finish2);
      if ( startDate > end2Date ) {
        this.form.controls.start.setValue(null);
        error = true;
      }
    }

    if (error) {
      this.displayService.showErrorToast( 'Hora incorrecte' );
    }
  }
  updateFinshDate(event) {
    let endDate = this.stringToDate(this.form.value.finish);
    let error = false;
    if (this.form.value.start !== undefined && this.form.value.start !== null ) {

      const startDate = this.stringToDate(this.form.value.start);
      if ( endDate < startDate ) {
        this.form.controls.finish.setValue(null);
        error = true;
      }
    }
    if (this.form.value.start2 !== undefined && this.form.value.start2 !== null ) {
      const start2Date = this.stringToDate(this.form.value.start2);
      if ( endDate < start2Date ) {
        this.form.controls.finish.setValue(null);
        error = true;
      }
    }
    if (this.form.value.finish2 !== undefined && this.form.value.finish2 !== null ) {
      const end2Date = this.stringToDate(this.form.value.finish2);
      if ( endDate < end2Date ) {
        this.form.controls.finish.setValue(null);
        error = true;
      }
    }
    
    if (error) {
      this.displayService.showErrorToast( 'Hora incorrecte' );
    }
    
  }
  updateStart2Date(event) {

    let start2Date = this.stringToDate(this.form.value.start2);    
    let error = false;
    if (this.form.value.start !== undefined && this.form.value.start !== null ) {

      const startDate = this.stringToDate(this.form.value.start);
      if ( start2Date < startDate ) {
        this.form.controls.start2.setValue(null);
        error = true;
      }
    }
    if (this.form.value.finish !== undefined && this.form.value.finish !== null ) {
      const endDate = this.stringToDate(this.form.value.finish);
      if ( start2Date > endDate ) {
        this.form.controls.start2.setValue(null);
        error = true;
      }
    }
    if (this.form.value.finish2 !== undefined && this.form.value.finish2 !== null ) {
      const end2Date = this.stringToDate(this.form.value.finish2);
      if ( start2Date > end2Date ) {
        this.form.controls.start2.setValue(null);
        error = true;
      }
    }
    
    if (error) {
      this.displayService.showErrorToast( 'Hora incorrecte' );
    }

  }
  updateFinsh2Date(event) {
    let end2Date = this.stringToDate(this.form.value.finish2);    
    let error = false;
    if (this.form.value.start !== undefined && this.form.value.start !== null ) {

      const startDate = this.stringToDate(this.form.value.start);
      if ( end2Date < startDate ) {
        this.form.controls.finish2.setValue(null);
        error = true;
      }
    }
    if (this.form.value.finish !== undefined && this.form.value.finish !== null ) {
      const endDate = this.stringToDate(this.form.value.finish);
      if ( end2Date > endDate ) {
        this.form.controls.finish2.setValue(null);
        error = true;
      }
    }
    if (this.form.value.start2 !== undefined && this.form.value.start2 !== null ) {
      const start2Date = this.stringToDate(this.form.value.start2);
      if ( end2Date < start2Date ) {
        this.form.controls.finish2.setValue(null);
        error = true;
      }
    }
    
    if (error) {
      this.displayService.showErrorToast( 'Hora incorrecte' );
    }
    
  }
  close(): void {
    this.displayService.hideModal();
  }

  update(): void {
    this.saveHorarios();
 
    const user = new UserTaxi({
      name: this.form.value.name,
      surnames: this.form.value.surnames,
      dni: this.form.value.dni,
      email: this.user.email,
      institution: this.user.institution,
      //city: this.form.value.city,
      start: this.form.value.start,
      finish: this.form.value.finish,
      start2: this.form.value.start2,
      finish2: this.form.value.finish2,
      workDays: this.form.value.workDays,
      secure_id: this.user.secure_id,
    }, null, this.horarios);

    this.userService.updateTaxi( user ).subscribe(
      () => {
        this.displayService.showToast( 'Perfil actualitzat correctament!' );
        this.userService.updateLocalUser(user);
        this.events.publish('taxiUpdated');
        this.close();
      }, err => {
        this.displayService.showErrorToast( err.message );
      }
    )
  }

  getCities(): void {
    this.cityService.getCities().subscribe(
      res => {
        this.cities = [];
        res.forEach( r => this.cities.push( { label: r.nombreOficial, value: r.id } ) );
      }
    );
  }

  getHorarios() {
    this.userService.getHorariosTaxi(this.user.secure_id).subscribe(res => {
      if (res === undefined || res === null) {
        this.horarios = [];
      } else {
        this.horarios = res;
      }
    }, err => { });
  }

  onDayChange() {
    this.saveHorarios();
    this.form.controls.start.setValue(null);
    this.form.controls.start2.setValue(null);
    this.form.controls.finish.setValue(null);
    this.form.controls.finish2.setValue(null);


    let day = this.form.get('workDays').value;
    this.horarioSelected = this.horarios.find(x => x.diaSemanaId.toString() === day);
    if (this.horarioSelected  === undefined || this.horarioSelected  === null) {
      this.horarioSelected = new HorarioDetalleDto();
      this.horarioSelected.diaSemanaId = day;
    }
  }

  saveHorarios() {
    let start = this.stringToDate(this.form.get('start').value);
    let finish = this.stringToDate(this.form.get('finish').value);
    let start2 = this.stringToDate(this.form.get('start2').value);
    let finish2 = this.stringToDate(this.form.get('finish2').value);
    if (this.horarioSelected  !== undefined && this.horarioSelected  !== null
      && this.horarioSelected.secureId !== undefined && this.horarioSelected.secureId !== null
      && this.horarioSelected.secureId !== '' ) {
        this.horarios.find(x => x.secureId === this.horarioSelected.secureId
           && x.diaSemanaId === this.horarioSelected.diaSemanaId ).horaInicio = start;
        this.horarios.find(x => x.secureId === this.horarioSelected.secureId
          && x.diaSemanaId === this.horarioSelected.diaSemanaId).horaInicio2 = finish2;
        this.horarios.find(x => x.secureId === this.horarioSelected.secureId
          && x.diaSemanaId === this.horarioSelected.diaSemanaId).horaPausa = start2;
        this.horarios.find(x => x.secureId === this.horarioSelected.secureId
          && x.diaSemanaId === this.horarioSelected.diaSemanaId).horaFin = finish;
      } else {
        if (this.horarioSelected.diaSemanaId !== -1) {
          this.horarioSelected.secureId = '-1|-1';

          this.horarioSelected.horaInicio = start;
          this.horarioSelected.horaFin = finish;
          this.horarioSelected.horaPausa = start2;
          this.horarioSelected.horaInicio2 = finish2;

          this.horarios.push(this.horarioSelected);

        }
      }
  }

  /* #region  OTHERS */

  stringToDate(value: Date) {
    if (value !== null && !this.isDate (value)) {
      value = new Date(value);
    }
    return value;
  }

  isDate(value: Date) {
    return value instanceof Date;
  }

  /* #endregion */


}
