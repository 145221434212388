import { Component, OnInit } from '@angular/core';
import { DisplayService, TaxiTripService } from 'src/app/services';
import { NavParams } from '@ionic/angular';
import { ServicioTaxistaDto } from 'src/app/models/servicio-taxistaDto.model';
import { SimpleTaxista } from 'src/app/models/simpleTaxista.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-delegate-service',
  templateUrl: './delegate-service.component.html',
  styleUrls: ['./delegate-service.component.scss'],
})
export class DelegateServiceComponent implements OnInit {
  private servicio: ServicioTaxistaDto;
  private selectedTaxi: string;
  public form: FormGroup;
  public listTaxistas: SimpleTaxista[];
  constructor(
    private taxiTripService: TaxiTripService,
    private navParams: NavParams,
    private displayService: DisplayService) {

     }

  ngOnInit() {
    this.servicio = this.navParams.data as ServicioTaxistaDto;
    this.taxiTripService.getTaxistas(this.servicio.secureId).subscribe(taxis => {
       this.listTaxistas = taxis;
     });
  }

  close(): void {
    this.displayService.hideModal();
  }

  onSelect( e: any ): void {
    this.selectedTaxi = e.detail.value;
  }
  delegarServicio(): void{
  this.taxiTripService.delegarServicio(this.servicio.secureId, this.selectedTaxi ).subscribe(taxis => {
        this.close();
      });
  }

}
