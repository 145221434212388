
import { NavParams, Platform, ModalController } from '@ionic/angular';
import { Component, OnInit, NgZone  } from '@angular/core';
//import { ServicioDetalleTaxistaDto } from 'src/app/models';
import { DisplayService, TaxiTripService, NotificationsService } from 'src/app/services';
import { ServicioDetalleTaxistaDto, TaxiTripState } from '../../../models/servicio-detalle-taxistaDto';
import { ContextMenuPopoverComponent } from '../../context-menu-popover/context-menu-popover.component';
import { ContextMenuPopover, Notificacio } from 'src/app/models';
import { AlertInput, AlertButton } from '@ionic/core';

import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';



import { PassajeroServicio } from 'src/app/models/passajero-servicio.model';
import { BehaviorSubject, ObservedValueOf } from 'rxjs';
import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';
import { ServicioTaxistaDto } from 'src/app/models/servicio-taxistaDto.model';
import { AdicionalPassangerOnService } from 'src/app/models/adicional-passanger-on-service.model';
import { AddPassengerComponent } from '../../user/add-passenger/add-passenger.component';
import { AcompananteServicioReturnDto } from 'src/app/models/acompanante-servicio-return.model';
import { PassangerToDelete } from 'src/app/models/passanger-to-delete.model';
import { NotificacionTaxista } from 'src/app/models/notificacionTaxista.model';
import { PassatgerDniModalComponent } from '../passatger-dni-modal/passatger-dni-modal.component';


@Component({
  selector: 'app-detail-taxi-service',
  templateUrl: './detail-taxi-service.component.html',
  styleUrls: ['./detail-taxi-service.component.scss'],
})
export class DetailTaxiServiceComponent implements OnInit {

  public trips: BehaviorSubject<ServicioTaxistaDto>;
  scannedData:any;
  barcodeScannerOptions: BarcodeScannerOptions;
  //public isFinish = false;

  constructor(
    private navParams: NavParams,
    private displayService: DisplayService,
    private taxiTripService: TaxiTripService,
    private platform: Platform,
    private barcodeScanner: BarcodeScanner,
    private notificationService: NotificationsService,
    private ngZone: NgZone,
    public modalCtrl: ModalController
  ) {
    this.trips = new BehaviorSubject(new ServicioTaxistaDto());
  }

  ngOnInit() {
    this.trips.next(this.navParams.data.trip as ServicioTaxistaDto);
  }

  close(): void {
    this.displayService.showLoading("close from taxi-detail");
    this.displayService.hideModal();
    setTimeout(() => { this.displayService.hideLoading()},1500);
  }

  acceptTrip(): void {
    this.taxiTripService.acceptTrip( this.trips.value ).subscribe(
      () => {
      console.log("acept event");
       this.close();
      },(err) => {
      console.log("acept event err");  
      }
    );
  }

  declineTrip(): void {
    this.taxiTripService.declineTrip( this.trips.value ).subscribe(() => {
      this.close();
    });
    this.close();
  }
  startTrip(): void {
    this.taxiTripService.startTrip( this.trips.value ).subscribe(
      () => {
       this.displayService.showToast('Servei iniciat correctament');
       setTimeout(() => { this.close() } , 1500);
      }
    );
  }

  endTrip(): void {
    if (this.trips.value.estado === 'ACCEPTED') {
      return;
    }
    if ( !this.puedeFinalizarTrayecto()) {
      this.displayService.showToast('No es pot finalitzar el servei, no hi ha cap usuari validat o no presentat!');
      return;
    }
    this.taxiTripService.endTrip( this.trips.value ).subscribe(
      () => {
        this.displayService.showToast('Servei finalitzat correctament');
        this.close();
      }
    );
  }
  puedeFinalizarTrayecto(): boolean {
    let puedeFinalizar = true;
    this.trips.value.serviciosTrayecto.forEach(viaje => {
        if (!viaje.userSolicitante.validado && viaje.userSolicitante.importeIva !== 0) {
          puedeFinalizar = false;
        }
        if (viaje.acompanantes !== undefined && viaje.acompanantes !== null && viaje.acompanantes.length > 0) {
          viaje.acompanantes.forEach( acompante => {
            if (!acompante.validado) {
              puedeFinalizar = false;
            }
          });
        }
    } );
    return puedeFinalizar;
  }
  getNombreMunicipio(servicioDetalle: ServicioDetalleTaxistaDto) {
    return this.trips.value.municipioNombre;
  }

  showCardStartEnd(): boolean {
    return this.trips.value.estado === TaxiTripState.ACCEPTED || this.trips.value.estado === TaxiTripState.PROGRESS;
  }

  showCardAcceptDecline(): boolean {
    return this.trips.value.estado === (TaxiTripState.NEW || TaxiTripState.CANCELLED);
  }

  ctxMenuPassatgers( e: Event, estado: TaxiTripState, secureIdRuta: string ): void {
    if(estado === TaxiTripState.PROGRESS){
      const props: ContextMenuPopover = {
        options: [
          { label: 'Validar per DNI', command: () => this.modalAddPassatgerDni(secureIdRuta), icon: 'add' },
          { label: 'Afegir NOU passatger', command: () => this.addPassenger(secureIdRuta), icon: 'add' },
          { label: 'Enviar missatge al usuari sol·licitant', command: () => this.modalMensaje(secureIdRuta), icon: 'add' },
        ]
      };
      this.displayService.showPopover(ContextMenuPopoverComponent, e, props);
    } else if ( estado === TaxiTripState.ACCEPTED) {
        const props: ContextMenuPopover = {
          options: [
            { label: 'Enviar missatge al usuari sol·licitant', command: () => this.modalMensaje(secureIdRuta), icon: 'add' },
          ]
        };
        this.displayService.showPopover(ContextMenuPopoverComponent, e, props);
    }
  }

  modalMensaje(secureIdRuta: string): void {
    const inputs: AlertInput[] = [
      { id: 'MISSATGE', label: 'MISSATGE', name: 'missatge', type: 'text', placeholder: 'MISSATGE' }
    ];
    const buttons: AlertButton[] = [
      { text: 'Cancel·lar', handler: data => true },
      { text: 'Enviar', handler: data => this.sendMessage( data.missatge, secureIdRuta ) },
    ];

    this.displayService.showAlert(
      'Enviar missatge',
      null,
      inputs,
      buttons
    );
  }
  sendMessage(missatge: any, secureIdRuta: string): void {
    if ( missatge !== undefined && missatge !== null && missatge !== ''){
      let not: NotificacionTaxista = new NotificacionTaxista();
      not.secureIdRuta = secureIdRuta;
      not.mensaje = missatge;
      this.notificationService.sendNotifToUser( not ).subscribe(
        () => {
          this.displayService.hideModal();
          this.displayService.showToast('Missatge enviat correctament');
        }
      );
    }
  }

  async modalAddPassatgerDni(secureIdRuta: string) {
    const passatgerDniPickerModal = await this.modalCtrl.create({
      component: PassatgerDniModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: { secureIdRuta: secureIdRuta }
    });
    await passatgerDniPickerModal.present();

    passatgerDniPickerModal.onDidDismiss().then((data) => {
      const validacioDni = data['data'];
      this.addPassatgerDni(validacioDni.dni, validacioDni.menorSenseDni, secureIdRuta);
    });
  }

  async addPassenger(secureIdRuta: string) {
    let passanger: AdicionalPassangerOnService;
    const passangerPickerModal = await this.modalCtrl.create({
      component: AddPassengerComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: { passatger: passanger }
    });
    await passangerPickerModal.present();

    passangerPickerModal.onDidDismiss()
      .then((data) => {
        passanger = data['data'];
        if (passanger !== undefined && passanger !== null) {
          passanger.secureIdRuta = secureIdRuta;
          this.taxiTripService.AddPassangerOnService(passanger).subscribe(res =>{
            const newPass: AcompananteServicioReturnDto = res;
            const copy = Object.assign({}, this.trips.value);
            const rutaEncontrada: ServicioDetalleTaxistaDto = copy.serviciosTrayecto.
            find(r => r.secureIdRuta === secureIdRuta);
            if (rutaEncontrada !== undefined) {
            rutaEncontrada.precio = newPass.importeRutaIva;
            rutaEncontrada.acompanantes.push(newPass.acompanante);
          }
            this.trips.next(copy);
          });
          //this.addicionalPassengers.push( passanger );
        }
      });
  }

  addPassatgerDni( dni: string, menorSenseDni: boolean, secureIdRuta: string ): void {
    const copy = Object.assign({}, this.trips.value);

    const rutaEncontrada: ServicioDetalleTaxistaDto = copy.serviciosTrayecto.
      find(r => r.secureIdRuta === secureIdRuta && ( r.userSolicitante.dni === dni
                                                    || r.acompanantes.find(a => a.dni === dni) !== undefined));
    if (rutaEncontrada !== undefined) {
      if (menorSenseDni) {
        const acompanantes = rutaEncontrada.acompanantes.filter(x => x.dni === dni);
        acompanantes.forEach(acompanante => {
          acompanante.validado = true;
        });

        this.taxiTripService.validarMenorSenseDni(rutaEncontrada).subscribe();
        this.displayService.showToast('Passatgers menors sense dni validats correctament');
      } else {
        if (rutaEncontrada.userSolicitante.dni === dni ) {
          this.ngZone.run(() => {
            rutaEncontrada.userSolicitante.validado = true;
            this.taxiTripService.validarSolicitanteDni(dni, rutaEncontrada).subscribe();
            this.displayService.showToast('Passatger validat correctament');
          });
        } else {
          const index = rutaEncontrada.acompanantes.findIndex(x => x.dni === dni);
          if (index !== undefined && index !== null && index !== -1) {
            this.ngZone.run(() => {
              const acomp = rutaEncontrada.acompanantes[index];
              rutaEncontrada.acompanantes.splice(index, 1);
              acomp.validado = true;
              rutaEncontrada.acompanantes.push(acomp);
              this.taxiTripService.validarPassatgerDni(dni, rutaEncontrada).subscribe();
              this.displayService.showToast('Passatger validat correctament');
            });
          } else {
            this.displayService.showToast('Passatger NO vàlid');
          }
        }
      }
    } else {
      this.displayService.showToast('Passatger NO vàlid');
    }
    this.trips.next(copy);
  }

  scanQR(): void {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.barcodeScannerOptions = {
        showTorchButton: true,
        showFlipCameraButton: true
      };
      this.scanCode();
     }
  }

  scanCode() {
   /* this.barcodeScanner
      .scan()
      .then(barcodeData => {
        //alert("Barcode data " + JSON.stringify(barcodeData));
        this.scannedData = barcodeData;
             //
             if(this.trip.userSolicitante.secureId==this.scannedData.text){
              this.trip.identidatConfirmat = true;
              this.displayService.showToast('Identidat de passatger confirmat!');   
             }else{
              this.displayService.showAlert('Aquest codi no coinsideix amb passatger del trajecte!');
             }
      })
      .catch(err => {
        console.log("Error", err);
      }); */
  }

  deleteSolicitant(secureId: string, dni: string, nombre: string, apellido1: string, apellido2: string, secureIdRuta: string) {
    const buttons: AlertButton[] = [
      { text: 'Cancel·lar', handler: () => true },
      {
        text: 'Acceptar', handler: () => {
          if ( secureId !== undefined && secureId !== null) {
            // Usuari Registrat, cridem el metode borrar Usuari Registrat
              this.solicitantNoPresentat(secureId, secureIdRuta);
          }
        }
      }
    ];
    this.displayService.showAlert(
      'Eliminar sol·licitant',
      'Estàs segur que vols eliminar el sol·licitant ' + apellido1 + ' ' + apellido2 + ', ' + nombre + '?',
      null,
      buttons
    );
  }

  solicitantNoPresentat(secureId: string, secureIdRuta: string) {
    const copy = Object.assign({}, this.trips.value);
    const rutaEncontrada: ServicioDetalleTaxistaDto = copy.serviciosTrayecto.
    find(r => r.secureIdRuta === secureIdRuta && ( r.userSolicitante.secureId !== secureId) !== undefined);

    if (rutaEncontrada !== undefined) {
      this.ngZone.run(() => {
        const solicitanteDel: PassangerToDelete =  this.getPassangerToDelete(rutaEncontrada.userSolicitante, rutaEncontrada.secureIdRuta);
        this.taxiTripService.deleteSolicitantOnTrip(solicitanteDel).subscribe( result => {
          if (result === true) {
            rutaEncontrada.precio = Number((rutaEncontrada.precio - rutaEncontrada.userSolicitante.importeIva).toFixed(2));
            rutaEncontrada.userSolicitante.importeIva = 0;
          }
        });
      });
    }

    this.trips.next(copy);
  }

  deletePassanger(secureId: string, dni: string, nombre: string, apellido1: string, apellido2: string, secureIdRuta: string) {
    const buttons: AlertButton[] = [
      { text: 'Cancel·lar', handler: () => true },
      {
        text: 'Acceptar', handler: () => {
          if ( secureId !== undefined && secureId !== null) {
            // Usuari Registrat, cridem el metode borrar Usuari Registrat
              this.deletePassangerRegistred(secureId, secureIdRuta);
          }
        }
      }
    ];
    this.displayService.showAlert(
      'Eliminar passatger',
      'Estàs segur que vols eliminar el passatger ' + apellido1 + ' ' + apellido2 + ', ' + nombre + '?',
      null,
      buttons
    );
  }

  deletePassangerRegistred(secureId: string, secureIdRuta: string) {
    const copy = Object.assign({}, this.trips.value);
;
    // busquem ruta per id acompanant
    const rutaEncontrada: ServicioDetalleTaxistaDto = copy.serviciosTrayecto.
      find(r => r.secureIdRuta === secureIdRuta && ( r.acompanantes.find(a => a.secureId === secureId) !== undefined));

    if (rutaEncontrada !== undefined) {
       const index = rutaEncontrada.acompanantes.findIndex(x => x.secureId === secureId);
       if (index !== undefined && index !== null && index !== -1) {
        this.ngZone.run(() => {
          const acomp = rutaEncontrada.acompanantes[index];
          const acompDel: PassangerToDelete =  this.getPassangerToDelete(rutaEncontrada.acompanantes[index], rutaEncontrada.secureIdRuta);
          this.taxiTripService.deletePassangerOnTrip(acompDel).subscribe( result => {
            if (result === true) {
              rutaEncontrada.acompanantes.splice(index, 1);
              rutaEncontrada.precio = Number((rutaEncontrada.precio - acomp.importeIva).toFixed(2));
            }
          });
        });
       }
    }

    this.trips.next(copy);
  }

  getPassangerToDelete(passajero: any, secureIdRuta: string): PassangerToDelete{
    const pass = new PassangerToDelete();
    pass.apellido1 = passajero.apellido1;
    pass.apellido2 = passajero.apellido2;
    pass.nombre = passajero.nombre;
    pass.dni = passajero.dni;
    pass.edad = passajero.edad;
    pass.secureIdUsuario = passajero.secureId;
    pass.secureIdRuta = secureIdRuta;

    return pass;
  }
}
