import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageKeys } from '../config/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // object to quickly access stored data
  public readonly memoryStorage: { [key in StorageKeys]?: any; } = {};

  constructor(
    private storage: Storage
  ) { }

  public async set( key: StorageKeys, value: any ): Promise<void> {
    this.memoryStorage[ key ] = value;
    return await this.storage.set( key, value );
  }

  public async get( key: StorageKeys ): Promise<any> {
    return await this.storage.get( key );
  }

  public async remove( key: StorageKeys ): Promise<void> {
    delete this.memoryStorage[ key ];
    return await this.storage.remove( key );
  }

  public async has( key: StorageKeys ): Promise<boolean> {
    const value = await this.storage.get( key );
    return value !== null || value !== undefined;
  }

  public async getStoredObjects( ) {
    await this.storage.length().then((len) => {
      if (len > 0){
          this.storage.forEach(
          (value, key) => {
            console.log(`Storage error, key: ${ key } ${ value } ${ StorageKeys }`);
            if ( Object.values(StorageKeys).includes(key as StorageKeys) ) {
              this.memoryStorage[key] = value;
            } else {
              console.log(`Storage error, key: ${ key }`);
            }
          }
        );
      } else {
        return;
      }
    }).catch((e) => {
      console.log('Error LocalStorage', e);
    });
  }

}
