import { Component, OnInit } from '@angular/core';
import { DisplayService } from 'src/app/services';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-triptic-user-service',
  templateUrl: './triptic-user-service.component.html',
  styleUrls: ['./triptic-user-service.component.scss'],
})
export class TripticUserServiceComponent implements OnInit {

  title: string;
  data: string;

  pdf: string;
  url: string;
  zoom: number = 1;
  totalPages: number;
  page: number = 1;
  currentZoom: any;
  isLoaded: boolean = false;

  constructor(
    private displayService: DisplayService,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.title = this.navParams.data.title;
    this.data = this.navParams.data.data;
  }

  close() {
    this.displayService.hideModal();
  }

  zoomIn() {
    this.zoom += 0.20;
  }

  zoomOut() {
    this.zoom -= 0.20;
  }

  nextPage() {
    this.page += 1;
  }

  previousPage() {
    this.page -= 1;
  }

  download() {
    window.open(this.data, '_system', 'location=no');
  }

  pinchin(event: any): void {
    this.zoom -= 0.04;
  }

  pinchout(event: any): void {
    this.zoom += 0.04;
  }
  afterLoadComplete(pdfData: any) {
    //
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }
}
