import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// =====================
// Ionic modules
// =====================
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { Device } from '@ionic-native/device/ngx';

// =====================
// Deps modules
// =====================
import { CalendarModule } from 'ion4-calendar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// =====================
// App modules
// =====================
import { AppRoutingModule } from './app-routing.module';
import { ServicesModule } from './services/services.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';

// =====================
// App components
// =====================
import { HttpInterceptorService } from './services/http-interceptor.service';
import { AppComponent } from './app.component';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
//Qr Scanner
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
//Header Logo
import { HeaderLogoModule } from './shared_modules/header-logo/header-logo.module';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode:'md'
    }),
    IonicStorageModule.forRoot({
      name: '__amtad',
      driverOrder: ['localStorage','sqlite','websql','indexeddb']
    }),
    ComponentsModule,
    PipesModule,
    AppRoutingModule,
    HeaderLogoModule,
    ServicesModule,
    HttpClientModule,
    CalendarModule,
    Ionic4DatepickerModule,
    LeafletModule.forRoot()
  ],
  providers: [
    Device,
    StatusBar,
    SplashScreen,
    Geolocation,
    FirebaseX,
    Badge,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {}
