import { StoppingPoint } from './stopping-point.model';
import { UserResumen } from './user-resumen.model';
import { ServicioDetalleTaxistaDto } from '.';

export enum TaxiTripState {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    NEW = 'NEW',
    FINISH = 'FINISH',
    CANCELLED = 'CANCELLED',
    PROGRESS = 'PROGRESS',
    ERROR = 'ERROR',
    DELEGATION = 'DELEGATE-IN-PROGRESS',
}

export class ServicioTaxistaDto {
    secureId: string;
    id: number;
    idHistoric: number;
    secureIdSolicitud: string;
    estado: TaxiTripState;
    municipioNombre: string;
    municipioId: string;
    zonaOrigen: string;
    zonaDestino: string;
    fechaSerivicio: Date;
    secureIdTaxista: string;
    nombreTaxista: string;
    respuestaTaxista: boolean;
    precio: number;
    serviciosTrayecto: ServicioDetalleTaxistaDto[];
}
