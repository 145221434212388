import { RutaTrayectoTaxistaDetalle } from './ruta-trayecto-taxista-detalle.model';

export class TrayectoTaxistaDetalle {
    public expanded: boolean = false;
    public id: number;
    public secureId: string;
    public estado: string;
    public zonaOrigen: string;
    public zonaDestino: string;
    public fechaSerivicio: Date;
    public secureIdTaxista: string;
    public nombreTaxista: string;
    public precio: number;
    public municipioNombre: string;
    public municipiId: number;

    public rutas: RutaTrayectoTaxistaDetalle[];
}
