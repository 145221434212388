import { Injectable } from '@angular/core';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageKeys } from '../config/storage';
import { Location } from '../models';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  private lastPosition: Location;

  constructor(
    private geolocation: Geolocation,
    private storageService: StorageService
  ) {
    this.lastPosition = this.storageService.memoryStorage._LAST_POSITION;
  }

  public getCurrentPosition(): Observable<Location> {
    const ret: Observable<Location> = new Observable( observer => {
      this.geolocation.getCurrentPosition().then(
        res => {

          const location: Location = {
            latitude: res.coords.latitude,
            longitude: res.coords.longitude,
            accuracy: res.coords.accuracy,
            altitude: res.coords.altitude,
            altitudeAccuracy: res.coords.altitudeAccuracy,
            heading: res.coords.heading,
            speed: res.coords.speed
          };

          observer.next( location );
          this.setLastPosition( location );
        }, err => {
          observer.error( err );
        }
      );
    } );
    return ret;
  }

  private setLastPosition( pos: Location ): void {
    (async () => {
      this.lastPosition = pos;
      await this.storageService.set( StorageKeys.lastPosition, pos );
    })();
  }

  public getLastPosition(): Location {
    if ( this.lastPosition ) {
      return this.lastPosition;
    } else {
      (async () => {
        this.lastPosition = await this.storageService.get( StorageKeys.lastPosition );
      })();
      return this.lastPosition;
    }
  }
}
