import { Component, OnInit } from '@angular/core';
import { AdicionalPassanger } from 'src/app/models/adicional-passanger.model';
import { NavParams, ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-passenger',
  templateUrl: './add-passenger.component.html',
  styleUrls: ['./add-passenger.component.scss'],
})
export class AddPassengerComponent implements OnInit {

  public form: FormGroup;
  public passenger: AdicionalPassanger = null;
  public auxDni: string;
  constructor(private navParams: NavParams, private modalController: ModalController) { }

  ngOnInit() {
    this.passenger = this.navParams.get('passatger');
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      surname1: new FormControl('', Validators.required),
      surname2: new FormControl('', Validators.required),
      dni: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required)
    });
  }
  dismissModal() {

    if (this.form.valid) {
      const formValue = this.form.value;
      const passajero: AdicionalPassanger = {
      secureId: '',
      nombre : formValue.name,
      apellido1 : formValue.surname1,
      apellido2 : formValue.surname2,
      edad : formValue.age,
      dni : formValue.dni,
      importe: null,
      };

      this.modalController.dismiss( passajero );
    }
  }

  register() {
    this.dismissModal();
  }

  close() {
    this.modalController.dismiss();
  }

  mayorEdad() {
    return this.form.value.age >= 16;
  }

  onAgeChange(event: any) {
    if (event.detail.value < 16) {
      this.form.setControl('dni', new FormControl(''));
    } else {
      this.form.setControl('dni', new FormControl('', Validators.required));
    }

  }
}
