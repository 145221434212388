import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserAbstract, UserTaxi, UserKind, User, Institution } from '../models';
import { DisplayService } from './display.service';
import { StorageService } from './storage.service';
import { ObjectUtils } from '../utils';
import { StorageKeys } from '../config/storage';
import { UserType } from '../models/user-type.model';

// #region Mock Data

//import { config } from '../../../tmp/config';
import { City } from '../models/city.model';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';
import * as querystring from 'querystring';
import { HttpClient } from '@angular/common/http';
import { LoginResponseBody, LoginResponseUserData } from '../models/login-res.model';
import { UserAbstractInterface } from '../models/user-abstract.model';
import { HorarioDetalleDto } from '../models/horario-detalleDto.model';
import { Platform } from '@ionic/angular';

// #endregion


@Injectable({
  providedIn: 'root'
})
export class UserService {



  private recentlyLogged = false;

  private user: UserAbstract;
  /*private userTypes: UserType[] = [
    { id: 1, name: 'Residents', description: 'Usuaris empadronats al municipi'},
    { id: 2, name: 'PMR', description: 'Usuaris amb incapacitat superior al 30%'},
    { id: 3, name: 'Menor edat', description: 'Usuaris amb  menys de 18 anys'},
  ];*/

  constructor(
    private storageService: StorageService,
    private http: HttpClientService,
    private platform: Platform
  ) {
    this.user = this.storageService.memoryStorage._USER;
  }


  login( email: string, password: string, institution: Institution, plataform: string, deviceId: string, pushToken: string ) {
    const body = {
      grant_type: 'password',
      username: email,
      password,
      institution: institution.id,
      plataform,
      deviceId,
      pushToken,
      requiredRol: 'AMBIGUOUS-APP' };
    return this.http.loginPost( EndpointsApi.login, body ).pipe(
      map( async ( r: LoginResponseBody ) => {
          const userData: LoginResponseUserData = JSON.parse(r.userdata);
          if ( userData && r.access_token ) {
            this.recentlyLogged = true;
            const tokenExpiration = new Date();
            tokenExpiration.setSeconds( tokenExpiration.getSeconds() + r.expires_in );

            const userApp: UserAbstractInterface = {
              name: userData.NOMBRE,
              surnames: userData.APELLIDO1 + ' ' + userData.APELLIDO2,
              email: userData.CORREO,
              dni: userData.DNI,
              institution: institution.id,
              token: r.access_token,
              secure_id: userData.SECURE_ID,
              tokenExpiration
            };

            if ( userData.ROL.NOMBRE === 'CLIENT-APP' ) {
              // Usuari
              const res = await this.http.getWithUrlParams( EndpointsApi.getMenores.replace('{secureId}', userApp.secure_id)).toPromise();
              const secId = userApp.secure_id;
              const resUser = await this.http.getWithUrlParams(
                EndpointsApi.getUserApp.replace('{secureId}', secId).replace('{institutionId}',
                userApp.institution.toString())).toPromise();
              this.user = new User( userApp, resUser.tiposUsuario, res, resUser );
            } else if ( userData.ROL.NOMBRE === 'TAXISTA' ) {
              const res = await this.http.getWithUrlParams( EndpointsApi.getVehicles.replace('{secureId}', userApp.secure_id)).toPromise();
              this.user = new UserTaxi( userApp, res, null );
            }
          }

          this.storageService.set( StorageKeys.user , this.user );
          return this.user;
        })
      );

    /*
    const loginObservable: Observable<UserAbstract> = new Observable(observer => {
      setTimeout(async () => {
        const userFind: UserAbstract = ObjectUtils.clone( config.login.find(
          e => e.email === email && e.password === password
        ) );

        if ( userFind ) {

          delete userFind.password;

          if ( userFind.kind === UserKind.USER ) {
            this.user = new User( userFind );
          } else if ( userFind.kind === UserKind.TAXI ) {
            this.user = new UserTaxi( userFind );
          }

          await this.storageService.set( StorageKeys.user , this.user);

          observer.next(this.user);

        } else {
          observer.error();
        }
      }, 1000);
    });
    return loginObservable;
    */
  }
  /*loadMenors() {
    this.http.getWithUrlParams( EndpointsApi.getMenores.replace('{secureId}', this.user.secure_id)).subscribe( res => {
      this.user = new User( this.user, userData.TIPO_USUARIO_FK, res );
    });
  } */

  setUser(userNew: UserAbstract) {
    this.user = userNew;
  }

  logout(): Observable<void> {
    const logoutObservable: Observable<void> = new Observable(observer => {
      this.user = undefined;
      this.storageService.remove( StorageKeys.user );
      observer.next();
    });
    return logoutObservable;
  }
  getUserTaxi(): Observable<UserAbstract> {
    return null;
    /*this.http.getWithUrlParams( EndpointsApi.getVehicles.replace('{secureId}', userApp.secure_id)).subscribe(usuario =>{
      this.user = new UserTaxi( usuario, res, null );
    }*/
  }
  getUserApp(): Observable<UserAbstract> {
    var usuario;
    this.http.getWithUrlParams( EndpointsApi.getMenores.replace('{secureId}', this.user.secure_id)).subscribe(menors =>{
      const secId = this.user.secure_id;
      this.http.getWithUrlParams(
        EndpointsApi.getUserApp.replace('{secureId}', secId).replace('{institutionId}',
        this.user.institution.toString())).subscribe(datosUsuario =>{
          this.user = new User( datosUsuario, datosUsuario.tiposUsuario, menors, datosUsuario );
          usuario = this.getUser();
        });
    });
    return usuario;
  }

  getUser(): UserAbstract {
    // return this.user || this.storageService.memoryStorage._USER;
    return this.user;
  }

  isUserLogged(): boolean {
    // Comprovamos que haya token (y que no esté expirado)
    let isTokenExpirated = true;
    if (this.user !== undefined && this.user.tokenExpiration !== undefined) {
      isTokenExpirated = this.user.tokenExpiration > new Date();
    }
    let valido = !!this.user || isTokenExpirated;

    if (valido) {
      // Comprovamos que sea web o mobil (ya que si es web siempre debemos hacer login).
      const isWeb = !this.platform.is('android') && !this.platform.is('ios');
      valido = (isWeb && this.recentlyLogged) || (!isWeb);
    }

    return valido;
  }

  registerUser( user: User ){
    return this.http.post( EndpointsApi.registerUser,  user).pipe(
      map( ( r: LoginResponseBody ) => {
         /* const userData: LoginResponseUserData = JSON.parse(r.userdata);

          if ( userData && r.access_token ) {

            const tokenExpiration = new Date();
            tokenExpiration.setSeconds( tokenExpiration.getSeconds() + r.expires_in );

            const userApp: UserAbstractInterface = {
              name: userData.NOMBRE,
              surnames: userData.APELLIDO1 + ' ' + userData.APELLIDO2,
              email: userData.CORREO,
              dni: userData.DNI,
              city: user.city,
              token: r.access_token,
              secure_id: userData.SECURE_ID,
              tokenExpiration
            }

            if ( userData.ROL.NOMBRE === 'CLIENT-APP' ) {
              // Usuari
              this.user = new User( userApp );
            } else if ( userData.ROL.NOMBRE === 'TAXISTA' ) {
              this.user = new UserTaxi( userApp );
            }
          }

          this.storageService.set( StorageKeys.user , this.user );*/
        return this.user;

        })
      );


    /*const ret: Observable<void> = new Observable(observer => {
      setTimeout(() => {
        observer.next();
      }, 1500);
    });
    return ret;*/
  }

  registerTaxi( user: UserTaxi ): Observable<void> {
    const ret: Observable<any> = new Observable(observer => {
      setTimeout(() => {
        observer.next();
      }, 1500);
    });
    return ret;
  }

  updateLocalUser(user: UserTaxi) {
    this.user = user;
  }
  updateLocalUserProfile(user: User) {
    this.user = user;
  }

  changePassword( oldPassword: string, newPassword: string ): Observable<void> {
    const secureId = this.user.secure_id;
    return this.http.post(EndpointsApi.changePwd, { secureId, oldPassword, newPassword}).pipe(
      map( ( ) => {
        this.user.password = newPassword;
        this.storageService.set( StorageKeys.user, this.user );
      })
    );
  }

  setFreeDays( freeDays : string[]) : Observable<void> {
    let taxiSecureId = this.user.secure_id;
    let freeDaysObject = { taxiSecureId, freeDays}
    return this.http.post(EndpointsApi.setFreeDays, freeDaysObject);
  }

  getFreeDays(secureId: string): Observable<string[]> {

    return this.http.getWithUrlParams(EndpointsApi.getFreeDays.replace('{secureId}', secureId), null).pipe(map(res => {
      const ret = res as string[];

      return ret;
    }));

  }

  deleteAccount(motivo: string): Observable<void> {
    let user = this.getUser();
    let deleteAccount= {
      secureId: user.secure_id,
      motiu: motivo
    }

    return this.http.post(EndpointsApi.deleteAccount, deleteAccount);
    /*const ret: Observable<void> = new Observable(observer => {
      setTimeout(() => {
        observer.next();
      }, 1000);
    });
    return ret;*/
  }

  updateUser( user: User ): Observable<any> {
    return this.http.post(EndpointsApi.updateUser, user).pipe(
      map( ( ) => {
          this.storageService.set( StorageKeys.user , user );
          this.user = user;
        })
      );
  }

  setMunicipio(municipioId: number) {
    this.user.city = municipioId;
  }

  updateTaxi( user: UserTaxi ): Observable<any> {
    return this.http.post(EndpointsApi.updateTaxi, user).pipe(
      map( ( ) => {
          this.storageService.set( StorageKeys.user , user );
          this.user = user;
        })
      );
  }

  getToken(): string {
    return this.user.token;
  }

  // getUserTypes(): Observable<UserType[]> {
  //   const ret: Observable<UserType[]> = new Observable( observer => {
  //     observer.next(this.user.);
  //   });
  //   return ret;
  // }

  addDependant( name: string, surnames: string, dni?: string ): Observable<any> {
    const ret: Observable<any> = new Observable(observer => {
      observer.next();
    });
    return ret;
  }

  addProfile( profileIds: number[] ): Observable<any> {
    const ret: Observable<any> = new Observable(observer => {
      observer.next();
    });
    return ret;
  }

  resetPassword( email: string ): Observable<void> {
    let username={username: email};
    return this.http.post(EndpointsApi.resetPwd, username).pipe(
      map( ( ) => {
          
        })
      );
  }

  getVehiclesTaxista(): void {

  }

  getHorariosTaxi(secure_id: string): Observable<HorarioDetalleDto[]> {
    const userId = this.user.secure_id;
    return this.http.getWithUrlParams( EndpointsApi.horariosTaxistas.replace('{secureId}', userId)).pipe( map( res => {
      return res;
    }));
  }

}
