import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleBrand, Vehicle } from '../models';
import { UserService } from './user.service';
import { UserTaxi } from '../models/user-taxi.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private userService: UserService
  ) { }

  getVehiclesOfTaxiUser(): Observable<Vehicle[]> {
    //Data user logged
    const user = this.userService.getUser() as UserTaxi;
    const ret: Observable<Vehicle[]> = new Observable( observer => {
      observer.next(user.vehicles);
    });
    return ret;
  }

}
