import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayService } from '../../../services/display.service';
import { MapComponent, markerColor } from '../../map/map.component';
import { NavParams } from '@ionic/angular';
import { Service, WeekDay, StoppingPoint } from 'src/app/models';
import { DateService } from '../../../services/date.service';
import { UserTripsService } from '../../../services/user-trips.service';
import { TripticUserServiceComponent } from '../triptic-user-service/triptic-user-service.component';
import { AddUserServiceComponent } from '../add-user-service/add-user-service.component';
import { FicheroDto } from 'src/app/models/FicheroDto.model';


@Component({
  selector: 'app-detail-user-service',
  templateUrl: './detail-user-service.component.html',
  styleUrls: ['./detail-user-service.component.scss'],
})
export class DetailUserServiceComponent implements OnInit {

  @ViewChild(MapComponent) map: MapComponent;
  service: Service;

  daysOfWeek: WeekDay[];
  public displayedCard: string;
  public displayedRoute: number;
  private serviceStartingPoints: StoppingPoint[];
  private promisecount: number; 
  public files: FicheroDto[];

  constructor(
    private displayService: DisplayService,
    private navParams: NavParams,
    private dateService: DateService,
    private userTripService: UserTripsService
  ) { }

  ngOnInit() {
    this.initLoading();
    this.service = this.navParams.data.service as Service;
    this.getDaysOfWeek();
    this.getServiceStartingPoints();
    this.getIsFavService();
    this.getFiles();
  }
  getFiles() {
    this.files = [];
    this.userTripService.getListTriptic( this.service.secureId).subscribe(res => {
      res.forEach(el => {
        this.files.push(el);
      });
    });
  }
  initLoading() {
    this.displayService.showLoading("detai-user-service solService");
    this.promisecount = 0;
  }
  promiseCounter(){
    this.promisecount ++;
    console.log(this.promisecount);
    if (this.promisecount >= 3){
      this.displayService.hideLoading();
      this.promisecount = 0;
    }
  }
  getIsFavService() {
    this.userTripService.getServiceFab( this.service.secureId).subscribe(res => {
      if(res !== undefined && res !== null){
        this.service.fab = true;
      }else {
        this.service.fab = false;
      }
      this.promiseCounter();
    });
  }

  close(): void {
    this.displayService.hideModal();
  }

  getDaysOfWeek(): void {
    this.daysOfWeek = this.dateService.getDaysOfWeek();
    this.promiseCounter();
  }

  getDayBadges() {
    const ret = [];
    this.daysOfWeek.forEach(d => {
      if (this.service.diasSemanaActivo.includes(d.idx)) {
        ret.push({ label: d.short, color: 'primary' });
      } else {
        ret.push({ label: d.short, color: 'medium' });
      }
    });
    return ret;
  }

  displayCard( card: string ): boolean {
    return this.displayedCard === card;
  }

  toggleCard( e: Event, card: string ): void {
    e.stopPropagation();
    this.displayedCard = this.displayedCard === card ? undefined : card;
    this.displayedRoute = undefined;
  }

  getCardIcon( card: string ): string {
    return this.displayedCard === card ? 'arrow-up' : 'arrow-down';
  }

  toggleHourDetails( idx: number ) {
    this.displayedRoute = this.displayedRoute === idx ? undefined : idx;
  }

  fab(): void {
    this.displayService.showLoading("detai-user-service fab");
    this.userTripService.updateServiceFab( this.service.secureId).subscribe(() => {
      this.service.fab = !this.service.fab;
      this.displayService.hideLoading();
    });
  }

  triptic(file: FicheroDto): void {
    //this.displayService.showLoading("detai-user-service triptic");
    const props: any = {
        title: file.nombreFichero,
        data: file.datos
      };
    //this.displayService.hideLoading();

    this.displayService.showModal( TripticUserServiceComponent, props );

  }

  solService(): void {
    const data: any = {
      service: this.service
    };
    this.displayService.showModal( AddUserServiceComponent, data );
  }

  getNotes(): string {
    let ret = '';
    if ( this.service.menoresPermitidos ) {
      ret += 'Es permeten menors';
      if ( this.service.menoresPermitidosConDocumentacion ) {
        ret += ', amb autorització.';
      } else {
        ret += '.';
      }
    }
    return ret;
  }

  getServiceStartingPoints(): void {
    this.userTripService.getServiceStartingPoints( this.service.secureId ).subscribe(
      res => {
        this.serviceStartingPoints = res;
        this.serviceStartingPoints.forEach( el => {
          this.map.addMarker( { lat: el.coordY, lng: el.coordX } );
        });
        this.map.centerMarkers();
        this.promiseCounter();
      }
    );
  }

}
