import { Component, OnInit } from '@angular/core';
import { DisplayService } from '../../services/display.service';
import { NavParams } from '@ionic/angular';
import { TextoLegalDto } from 'src/app/models/textoLegalDto.model';
import { InstitutionService } from 'src/app/services/institution.service';
import { TripticUserServiceComponent } from '../user/triptic-user-service/triptic-user-service.component';
import { FicheroDto } from 'src/app/models/FicheroDto.model';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  public legalText: string;
  public textoBoton: string;
  valores: TextoLegalDto[];
  file: FicheroDto;
  
  constructor(
    private displayService: DisplayService,
    private institutionService: InstitutionService,
    public navParams: NavParams
  ) { }

  ngOnInit() {
    //this.displayService.showLoading();
    this.legalText = this.navParams.get('text');
  // getLegalTextApp
    this.institutionService.getTextApp().subscribe(
    res => {
      this.valores = res;
      //this.displayService.hideLoading();
      if(this.valores.find(x => x.nombre === 'link') !== undefined) {
        this.textoBoton = this.valores.find(x => x.nombre === 'link').valor;
      }
    });
    this.getFiles();
  }

  close(): void {
    this.displayService.hidePopover();
  }
  getText(text: string) {
    this.legalText = text;
  }
  showPdf() {
    const props: any = {
      title: this.file.nombreFichero,
      data: this.file.datos
    };

    this.displayService.showModal( TripticUserServiceComponent, props );
  }
  listaFiltrada(): TextoLegalDto[] {
    return this.valores.filter( x => x.nombre !== 'link');
  }
  getFiles() {
    this.file = new FicheroDto();
    this.institutionService.getFilePrivacy().subscribe( res => {
      this.file = res;
    });
  }
}
