import { RegisterTaxiPageModule } from './../pages/shared/register/register-taxi/register-taxi.module';
import { Institution } from './../models/institution.model';
import { Injectable } from '@angular/core';
import { City } from '../models';
import { Observable } from 'rxjs';
import { DisplayService } from './display.service';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';
import { StorageService } from './storage.service';
import { StorageKeys } from '../config/storage';

@Injectable({
  providedIn: 'root'
})
export class CityService {
 

  private currentCity: City = this.storageService.memoryStorage._CITY;

  constructor(
    private displayService: DisplayService,
    private http: HttpClientService,
    private storageService: StorageService
  ) { }

  getCities(): Observable<City[]> {
    return this.http.get( EndpointsApi.getCities ).pipe( map( r => r as City[] ) );
  }

  getCititesOfInstitution(institucion: Institution): Observable<City[]> {
    return this.http.getWithUrlParams( EndpointsApi.getCitiesInstitution.replace('{secureId}', institucion.secureId.toString()) );
  }

  setCurrentCity( c: City ): void {
    this.currentCity = c;
    this.storageService.set( StorageKeys.city, this.currentCity );
  }

  setCurrentInstitution(idInstitution: number): void {
    if (this.currentCity === undefined) {
      // No tenemos informacion previa del municipio.
      this.currentCity = new City();
    }
    this.currentCity.institucionFk = idInstitution;
    this.storageService.set(StorageKeys.city, this.currentCity);
    this.storageService.set(StorageKeys.institution, idInstitution);
  }

  getCurrencCity(): City {
    return this.currentCity || this.storageService.memoryStorage._CITY;
  }

  getCityMessages(): Observable<any> {
    const currentCityId = this.getCurrencCity().id;
    const currentInstitutionId = this.getCurrencCity().institucionFk;
    return this.http.getWithUrlParams(
      EndpointsApi.getMensajesMunicipio.
        replace('{id}', currentCityId !== null && currentCityId !== undefined ? currentCityId.toString() : '-1').
        replace('{idInstitucion}', currentInstitutionId !== null && currentInstitutionId !== undefined ? currentInstitutionId.toString() : '-1'));
  }

  getText(city: City): Observable<any> {
    return this.http.getWithUrlParams( EndpointsApi.getLegalText.replace('{secureId}', city.id.toString()) );
  }
}
