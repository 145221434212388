import { Injectable } from '@angular/core';
import { PopoverController, LoadingController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { PopoverOptions, LoadingOptions, ToastOptions, ModalOptions, Color, AlertOptions, AlertInput, AlertButton } from '@ionic/core';
import { Observable } from 'rxjs';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  private popover: HTMLIonPopoverElement;
  private loading: any;
  private isLoadingInit: boolean;
  private toast: HTMLIonToastElement;
  private modal: HTMLIonModalElement;
  private alert: HTMLIonAlertElement;

  constructor(
    private popoverCtrl: PopoverController,
    public loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) { }

  // #region Popover

  public async showPopover( component: any, event?: Event, props?: any ) {

    if ( this.popover ) { this.hidePopover(); }

    const options: PopoverOptions = {
      component,
      translucent: true
    };

    if ( event ) { options.event = event; }
    if ( props ) { options.componentProps = props; }

    this.popover = await this.popoverCtrl.create(options);
    await this.popover.present();
  }

  public async showFullScreenPopover( component: any, props?: any) {
    if ( this.popover ) { this.hidePopover(); }
    const options: PopoverOptions = {
      component,
      translucent: false,
      cssClass: 'fullScreenPopover',
      showBackdrop: true
    };

    if ( props ) { options.componentProps = props; }
    this.popover = await this.popoverCtrl.create( options );
    await this.popover.present();
  }

  public hidePopover( data?: any ) {
    if ( this.popover ) {
      this.popover.dismiss( data );
      delete this.popover;
    }
  }

  // #endregion

  // #region Loading

  public showLoading(method?: string, message?: string ) {
    return new Promise((resolve) => {
      this.loading = true;
      this.isLoadingInit = true;
      const options: LoadingOptions = {
        spinner: 'bubbles',
        translucent: true,
      };
      if ( message ) { options.message = message; }
      this.loadingCtrl.create(options).then( a => {
        a.present().then( () => {
          console.log('presented from - ' + method);
          delete this.isLoadingInit;
          resolve('OK');
        });
      }).catch((e) => {
          console.log('Error presented' + e);
        });
    });
  }

  public hideLoading( data?: any ) {
    if ( this.loading ) {
      console.log('Loading dismissed');
      this.loadingCtrl.dismiss();
      this.loading = false;
    }
  }

  // #endregion

  // #region Toast

  public async showToast( message: string, duration: number = 1000, showCloseButton: boolean = null ) {

    if ( this.toast ) { this.hideToast(); }
    const options: ToastOptions = {
      duration,
      message,
      showCloseButton
    };

    this.toast = await this.toastCtrl.create( options );
    await this.toast.present();

  }

  public async showToastButton( message: string, showCloseButton: boolean = false ) {

    if ( this.toast ) { this.hideToast(); }

    const options: ToastOptions = {
      message,
      showCloseButton,
      closeButtonText: 'Tancar'
    };

    this.toast = await this.toastCtrl.create( options );
    await this.toast.present();

  }

  public async showErrorToast( message: string ) {

    if ( this.toast ) { this.hideToast(); }

    const options: ToastOptions = {
      duration: 1500,
      message,
      color: 'danger'
    };

    this.toast = await this.toastCtrl.create( options );
    await this.toast.present();

  }

  public hideToast( data?: any ) {
    if ( this.toast ) {
      this.toast.dismiss( data );
      delete this.toast;
    }
  }

  // #endregion

  // #region Modal

  public async showModal( component: any, props?: any ) {

    if ( this.modal ) { this.hideModal(); }

    const options: ModalOptions = {
      component
    };

    if (props) {  options.componentProps = props; }

    this.modal = await this.modalCtrl.create( options );
    await this.modal.present();

  }

  public async showModalAndClose( component: any, props?: any, backDropDismiss: boolean = true ): Promise<string> {
    if ( this.modal ) { this.hideModal(); }

    const options: ModalOptions = {
      component,
    };
    options.backdropDismiss = backDropDismiss;

    if (props) {  options.componentProps = props; }

    this.modal = await this.modalCtrl.create( options,  );
    await this.modal.present();
    const { data } = await  this.modal.onDidDismiss();
    if (data) {
     return 'true';
    }

  }

  public hideModal( data?: any ) {
    if ( this.modal ) {
      this.modal.dismiss( data );
      delete this.modal;
    }
  }

  // #endregion

  // #region Alert

  public async showAlert( header: string, message?: string, inputs?: AlertInput[], buttons?: AlertButton[] ) {

    if ( this.alert ) { this.hideAlert(); }

    const options: AlertOptions = {
      header
    };

    if ( message ) { options.message = message; }
    if ( inputs ) { options.inputs = inputs; }
    if ( buttons ) { options.buttons = buttons; }

    this.alert = await this.alertCtrl.create( options );
    await this.alert.present();

  }

  public hideAlert( data?: any ) {
    if (this.alert) {
      this.alert.dismiss( data );
      delete this.alert;
    }
  }

  // #endregion
}
