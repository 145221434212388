import { Injectable } from '@angular/core';
import { Faq } from '../models';
import { DisplayService } from './display.service';
import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private http: HttpClientService
  ) { }

  getFaqs(): Observable<Faq[]> {
    return this.http.get( EndpointsApi.getFaqs ).pipe( map( res => {
      const ret = res as Faq[];
      ret.forEach( i => i.visible = false );
      ret.sort( ( a, b ) =>  a.orden - b.orden );
      return ret;
    }));
  }


}
