export { TaxiTripService } from './taxi-trip.service';
export { LocaleService } from './locale.service';
export { PlatformService } from './platform.service';
export { VehicleService } from './vehicle.service';
export { CityService } from './city.service';
export { DateService } from './date.service';
export { DisplayService } from './display.service';
export { FaqService } from './faq.service';
export { NotificationsService } from './notifications.service';
export { StorageService } from './storage.service';
export { TabsService } from './tabs.service';
export { UserTripsService } from './user-trips.service';
export { UserService } from './user.service';
export { HttpClientService } from './http-client.service';
export { GeolocationService } from './geolocation.service';
export { HttpInterceptorService } from './http-interceptor.service';
export { TaxiSettlingsService } from './taxi-settlings.service';
export { InstitutionService } from './institution.service';
