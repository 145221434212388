export class City {
    secureId: string;
    id?: number;
    nombreOficial: string;
    institucionFk: number;


    constructor(secureId?: string, id?: number, nombreOficial?: string, institucionFk?: number) {
        this.secureId = secureId !== undefined ? secureId : '-1|-1';
        this.id = id;
        this.nombreOficial = nombreOficial !== undefined ? nombreOficial : '';
        this.institucionFk = institucionFk !== undefined ? institucionFk : -1;
    }
}
