import { Component, OnInit } from '@angular/core';
import { DisplayService } from '../../../services/display.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { User } from 'src/app/models';
import { StorageService } from 'src/app/services';
import { NavController, Events } from '@ionic/angular';


@Component({
  selector: 'app-edit-user-profile-modal',
  templateUrl: './edit-user-profile-modal.component.html',
  styleUrls: ['./edit-user-profile-modal.component.scss'],
})
export class EditUserProfileModalComponent implements OnInit {

  public form: FormGroup;
  private user: User;

  constructor(
    private navController: NavController,
    private displayService: DisplayService,
    private userService: UserService,
    public events: Events,

  ) { }

  ngOnInit() {
    this.user = this.userService.getUser() as User;
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(this.user.name, Validators.required),
      surnames: new FormControl(this.user.surnames, Validators.required),
      dni: new FormControl(this.user.dni, Validators.required),
      //city: new FormControl(this.user.city, Validators.required),
      address: new FormControl(this.user.address, Validators.required),
      tel: new FormControl(this.user.tel, [Validators.required, Validators.minLength(9)]),
      birth: new FormControl(this.user.birth, Validators.required)
    });
  }

  close(): void {
    this.displayService.hideModal();
  }

/* findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}*/
  update(): void {
    const user = new User({
      name: this.form.value.name,
      surnames: this.form.value.surnames,
      dni: this.form.value.dni,
      email: this.userService.getUser().email,
      institution: undefined,
      city: this.user.city,
      address: this.form.value.address,
      birth: this.form.value.birth,
      secure_id: this.userService.getUser().secure_id,
      tel: this.form.value.tel,
    }, null,this.user.dependants ,null);

    this.userService.updateUser(user).subscribe(
      () => {
        this.displayService.showToast('Usuari actualitzat correctament');
        this.userService.updateLocalUserProfile(user);
        this.events.publish('userUpdated');
        this.close();
      }, err => {
        this.displayService.showErrorToast(err.message);
      }
    );
  }

}
