export class UserAllServicesFilter {
    public fechaInicio: Date;
    public fechaActual: string;

    constructor() {
        this.fechaInicio = new Date();
        this.fechaInicio.setHours(0, 0, 0);
        this.fechaActual = this.fechaInicio.toJSON();
    }
}
