import { AdicionalPassanger } from './adicional-passanger.model';

export class SolicitudTrayectoDto {
    secureIdServicio: string;
    secureIdParadaOrigen: string;
    secureIdParadaDestino: string;
    secureIdHorarioRuta: string;
    seleccionHora: Date;
    secureIdUsuarioApp: string;
    secureIdUsuarioTaxi: string;
    estado: string;
    fechaSolicitud: Date;
    precioServicio: number;
    precioUsuario: number;
    securesIdsMenors: string[];
    passajeros: AdicionalPassanger[];
    securesIdsCaracteristicasTrayecto: string[];
    securesIdsCaracteristicasTaxi: string[];

    constructor(servicio: string, paradaOrigen: string, paradaDestino: string, horario: string,
                seleccionHora: Date, usuarioApp: string, usarioTaxi: string, estado: string, fecha: Date,
                precio: number, precioUsuario: number, menors: string[], acomp: AdicionalPassanger[],
                securesIdsCaracteristicasTrayecto: string[], securesIdsCaracteristicasTaxi: string[]) {
        this.secureIdServicio = servicio;
        this.secureIdParadaOrigen = paradaOrigen;
        this.secureIdParadaDestino = paradaDestino;
        this.secureIdHorarioRuta = horario;
        this.seleccionHora = seleccionHora;
        this.secureIdUsuarioApp = usuarioApp;
        this.secureIdUsuarioTaxi = usarioTaxi;
        this.estado = estado;
        this.fechaSolicitud =  fecha;
        this.precioServicio = precio;
        this.precioUsuario = precioUsuario;
        this.securesIdsMenors = menors;
        this.passajeros = acomp;
        this.securesIdsCaracteristicasTrayecto = securesIdsCaracteristicasTrayecto;
        this.securesIdsCaracteristicasTaxi = securesIdsCaracteristicasTaxi;
    }
}
