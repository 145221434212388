import { Institution } from './institution.model';
export { Notificacio } from './notificacio.model';
export { ServicioDetalleTaxistaDto, TaxiTripState } from './servicio-detalle-taxistaDto';
export { UserType } from './user-type.model';
export { StoppingPoint } from './stopping-point.model';
export { RouteSchedule } from './route-schedule.model';
export { WeekDay } from './week-day.model';
export { Location } from './location.model';
export { Vehicle } from './vehicle.model';
export { VehicleBrand } from './vehicle-brand.model';
export { Notification } from './notification.model';
export { Faq } from './faq.model';
export { Service } from './service.model';
export { Route } from './route.model';
export { UserTrip, UserTripState } from './user-trip.model';
export { ContextMenuPopover } from './context-menu-popover.model';
export { RouterPopover } from './router-popover.model';
export { City } from './city.model';
export { Tab } from './tab.model';
export { UserAbstract, UserKind } from './user-abstract.model';
export { User } from './user.model';
export { UserTaxi } from './user-taxi.model';
export { TrayectoTaxistaDetalle } from './trayecto-taxista-detalle.model';
export { RutaTrayectoTaxistaDetalle } from './ruta-trayecto-taxista-detalle.model';
export { TrayectoLiquidacion } from './trayecto-liquidacion.model';
export { RutaLiquidacion } from './ruta-liquidacion.model';
export { Institution } from './institution.model';