import { UserAbstract, UserKind, UserAbstractInterface } from './user-abstract.model';
import { UserType } from '.';

export interface UserInterface extends UserAbstractInterface {
    address?: string;
    tel?: string;
    birth?: string;
    dependants?: { secureId: string, name: string, surnames: string, dni: string }[];
    type?: UserType[];
    deshabilitat?: boolean;
}


export class User extends UserAbstract implements UserInterface {
    address: string;
    tel: string;
    birth: string;
    password: string;
    type: UserType[];
    institution: number;
    dependants: {  secureId: string, name: string, surnames: string, dni: string }[];
    deshabilitat?: boolean;

    constructor(init: UserInterface, tipus: UserType[], dependants: any[], resUser: any) {
        super(init);
        this.kind = UserKind.USER;
        Object.assign(this, init);
        this.type = tipus;
       /* if (tipus !== null) {
            tipus.forEach(x => {
            this.type.push(x);
            });
        }*/
        this.dependants = [];
        Object.assign(this.dependants, dependants);
        this.address = resUser == null ? this.address : resUser.direccion;
        this.tel = resUser == null ? this.tel : resUser.telefono;
        this.birth = resUser == null ? this.birth : resUser.fechaNacimiento;
        this.deshabilitat = resUser == null ? false : resUser.deshabilitat;
    }


}
