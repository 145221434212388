import { Component, OnInit, ViewChild, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-expandable-card-view',
  templateUrl: './expandable-card-view.component.html',
  styleUrls: ['./expandable-card-view.component.scss'],
})
export class ExpandableCardViewComponent implements OnInit {

  @ViewChild('expandWrapper', { read: ElementRef }) expandWrapper: ElementRef;
  @ViewChild('contentView') contentView: ElementRef;

  @Input('expanded') expanded = false;

  constructor(public renderer: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.expandWrapper.nativeElement, 'max-height', 'auto');
  }
}
