import { Component, OnInit, Input } from '@angular/core';
import { Platform, NavController, Events } from '@ionic/angular';
import { UserService, DisplayService, StorageService, CityService } from 'src/app/services';
import { ContextMenuPopoverComponent } from 'src/app/components/context-menu-popover/context-menu-popover.component';
import { AlertButton, AlertInput } from '@ionic/core';
import { ContextMenuPopover } from 'src/app/models';
import { StorageKeys } from 'src/app/config/storage';


@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})

export class HeaderLogoComponent implements OnInit {
  public isMobile = true;
  public logo: string;

  // tslint:disable-next-line: no-input-rename
  @Input('titulo')  titulo: string;
  // tslint:disable-next-line: no-input-rename
  @Input('profile')  profile: boolean;
  // tslint:disable-next-line: no-input-rename
  @Input('isTaxi')  isTaxi: boolean;
  constructor(
    private platform: Platform,
    private userService: UserService,
    private displayService: DisplayService,
    private navController: NavController,
    public events: Events,
    private storageService: StorageService,
    private cityService: CityService
  ) { }

  ngOnInit() {
    if(this.platform.is("ios") || this.platform.is("android")){
      this.isMobile = true;
    }

    this.storageService.get(StorageKeys.institutionLogo).then(logo => {
      if (logo !== undefined && logo !== null) {
        this.logo = logo;
      } else {
        this.logo = 'assets/logo_40.png';
      }
    });
  }

  isProfile(): boolean {
    if(this.profile !== undefined && this.profile !== null && this.profile) {
        return true;
    } else {
      return false;
    }
  }

  showOptions( e: Event ): void {
    if (this.isTaxi !== undefined && this.isTaxi !== null && this.isTaxi) {
      const options: ContextMenuPopover = {
        options: [
          { label: 'Tancar sessió', command: () => this.showModalDisconnect() },
          { label: 'Canviar contrasenya', command: this.showModalChangePassword.bind(this) }
        ]
      };
      this.displayService.showPopover( ContextMenuPopoverComponent, e, options );
  } else {
    const options: ContextMenuPopover = {
      options : [
        { label: 'Tancar sessió', command: () => this.showModalDisconnect() },
        { label: 'Canviar contrasenya', command: () => this.showModalChangePassword() },
        { label: 'Eliminar compte', command: () => this.showModalDeleteAccont() }
      ]
    };
    this.displayService.showPopover(ContextMenuPopoverComponent, e, options);
  }
  }

  showModalDisconnect(): void {
    const buttons: AlertButton[] = [
      { text: 'Cancel·lar', handler: data => true },
      { text: 'D\'acord', handler: data => this.disconnectAccountAndLeave() }
    ];
    this.displayService.showAlert(null, 'Segur que vols desconnectar el compte? Sortiràs de l\'aplicació.', null, buttons);
  }

  disconnectAccountAndLeave(): void {
    if ( this.platform.is('ios') ||  this.platform.is('android')){
      this.storageService.set("LoginUser" as StorageKeys,"");
      this.storageService.set("LoginPass" as StorageKeys,"");
      this.storageService.set("LoginCity" as StorageKeys,"");
    }
    this.userService.logout().subscribe(
      () => {
        this.storageService.remove(StorageKeys.city).then(() => {
          this.storageService.remove(StorageKeys.institution).then(() => {
            this.storageService.remove(StorageKeys.user).then(() => {
              this.storageService.remove(StorageKeys.institutionLogo).then(() => {
                this.navController.navigateRoot('/login');
              });
            });
          }) ;
        });
      }
    );
  }

  showModalChangePassword(): void {
    const inputs: AlertInput[] = [
      { placeholder: 'Contrasenya antiga', name: 'oldPassword', type: 'password' },
      { placeholder: 'Contrasenya nova', name: 'newPassword', type: 'password' },
      { placeholder: 'Repetir contrasenya', name: 'repeatPassword', type: 'password' }
    ];

    const buttons: AlertButton[] = [
      { text: 'Cancel·lar', handler: data => true },
      { text: 'Guardar', handler: data => this.changePassword( data ) },
    ];

    this.displayService.showAlert('Canviar contrasenya', null, inputs, buttons);

  }

  changePassword( data: any ): boolean {
    let valid = false;
    let message = '';
    if ( !data.oldPassword || data.oldPassword === '' ) {
      message = 'Emplena el camp de contrasenya antiga.';
    } else if ( !data.newPassword || data.newPassword === '' ) {
      message = 'Emplena el camp de contrasenya nova.';
    } else if ( data.newPassword !== data.repeatPassword ) {
      message = 'Les dues contrasenyes han de ser iguals.';
    } else {
      valid = true;
    }

    if ( !valid ) {
      this.displayService.showErrorToast(message);
      return false;
    } else {
      this.userService.changePassword( data.oldPassword, data.newPassword ).subscribe(
        () => {
          this.displayService.showToast('Contrasenya canviada correctament.', 3000);
          return true;
        }, err => {
          //
          if(err.status === 404){
            this.displayService.showToastButton(err.text, true);
          }
        }
      );
    }
  }

  showModalDeleteAccont(): void {

    const inputs: AlertInput[] = [
      { name: 'motivo', type: 'text', placeholder: 'Motiu de baixa'}
    ];
    const buttons: AlertButton[] = [
      { text: 'Cance·lar', handler: data => true },
      { text: 'Eliminar', handler: data => this.deleteAccount(data.motivo)},
    ];
    this.displayService.showAlert(
      'Eliminar compte',
      'Estàs segur que vols eliminar definitivament el compte?',
      inputs,
      buttons
    );
    /*let alert = this.alertCtrl.create({
      //title: 'Login',
      inputs: [
        {
          name: 'username',
          placeholder: 'Username'
        },
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Login',
          handler: data => {
          /*  if (User.isValid(data.username, data.password)) {
              // logged in!
            } else {
              // invalid login
              return false;
            }          }
        }
      ]
    });
    alert.create();*/

  }

  deleteAccount(motivo: string ): void {
    //

    this.userService.deleteAccount(motivo).subscribe(
      res => {
        this.displayService.showToast('Compte eliminat correctament.', 3000);
        this.navController.navigateRoot('/logout');
      }
    )
  }

}
