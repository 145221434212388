import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth-guard.service';
import { AppComponent } from './app.component';

const routes: Routes = [
  // Shareds
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/shared/login/login.module#LoginPageModule' },
  { path: 'logout', loadChildren: './pages/shared/logout/logout.module#LogoutPageModule' },
  { path: 'register-taxi', loadChildren: './pages/shared/register/register-taxi/register-taxi.module#RegisterTaxiPageModule' },
  { path: 'register-user', loadChildren: './pages/shared/register/register-user/register-user.module#RegisterUserPageModule' },

  // Taxi
  { path: 'taxi-tabs', loadChildren: './pages/taxi-tabs/taxi-tabs.module#TaxiTabsPageModule', canActivate: [AuthGuard] },

  // User
  { path: 'user-tabs', loadChildren: './pages/user-tabs/user-tabs.module#UserTabsPageModule', canActivate: [AuthGuard] },

  // No route
   { path: '', loadChildren: './pages/shared/login/login.module#LoginPageModule' },
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'user-qrcode', loadChildren: './pages/user-tabs/user-qrcode/user-qrcode.module#UserQrcodePageModule' },

  //{ path: 'taxi-qrscan', loadChildren: './pages/taxi-tabs/taxi-qrscan/taxi-qrscan.module#TaxiQrscanPageModule' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
