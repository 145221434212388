import { RutaLiquidacion } from './ruta-liquidacion.model';

export class TrayectoLiquidacion {
    public trayectoHistoricoId: number;
    public nombreServicio: string;
    public fechaInicial: Date;
    public zonaOrigenDescripcion: string;
    public zonaFinDescripcion: string;
    public costeTrayecto: number;
    public importeTotal: number;
    public taxistaId: number;
    public taxistaNombre: string;
    public taxistaApellido1: string;
    public taxistaApellido2: string;
    public expanded: boolean; // este campo solo se usa para controles de la vista.
    public rutas: RutaLiquidacion[];
}
