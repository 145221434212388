import { StoppingPoint } from './stopping-point.model';
import { UserResumen } from './user-resumen.model';

export enum TaxiTripState {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    NEW = 'NEW',
    FINISH = 'FINISH',
    CANCELLED = 'CANCELLED',
    PROGRESS = 'PROGRESS',
    ERROR = 'ERROR',
    DELEGATION = 'DELEGATE-IN-PROGRESS',
}

export interface ServicioDetalleTaxistaDto {
    secureId: string;
    secureIdSolicitud: string;
    secureIdRuta: string;
    identidatConfirmat: boolean;
    paradaOrigen: StoppingPoint;
    paradaDestino: StoppingPoint;
    fechaSerivicio: Date;
    precio: number;
    horaSalidaRuta: Date;
    estado: TaxiTripState;
    acompanantes: {
        secureId: string;
        nombre: string;
        apellido1: string;
        apellido2: string;
        dni: string;
        edad: number;
        validado: boolean;
        importeIva: number;
    }[];
    secureIdTaxista: string;
    respuestaTaxista: boolean;
    usuarioSolicitante: string; // TODO [somintec] - Ara mateix el back envia 
    userSolicitante: UserResumen;
    estadoPeticion: number;
    id: number; // TODO [somintec] - Enviar nº servei
    zonaOrigen: string; // TODO [somintec] - Enviar nom zona origen
    zonaDestino: string; // TODO [somintec] - Enviar nom zona destí
    nombreTaxista: string; // TODO [somintec] - Enviar nom del taxista
}
