import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserTrip, Service, StoppingPoint, UserTripState, UserAbstract } from '../models';
import { UserService } from './user.service';
import { DisplayService } from './display.service';
import { DateService } from './date.service';
import { RouteSchedule } from '../models/route-schedule.model';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { map } from 'rxjs/internal/operators';
import { UserTripFilter } from '../pages/user-tabs/user-services/user-services-filters/user-service-trip-filter';
import { equalParamsAndUrlSegments } from '@angular/router/src/router_state';
import { Paradas } from '../models/paradas.model';
import { FavService } from '../models/favService.model';
import { FicheroDto } from '../models/FicheroDto.model';
import { CaracteristicasTaxi } from '../models/caracteristicas-taxi.model';
import { CaracteristicasTrayecto } from '../models/caracteristicas-trayecto.model';
import { UserAllServicesFilter } from '../pages/user-tabs/user-all-services/user-all-services-filter/user-all-services-filter';
import { ServicesFilter } from '../pages/user-tabs/user-all-services/user-all-services-filter/user-service-trip-filter';

@Injectable({
  providedIn: 'root'
})
export class UserTripsService {

  private user: UserAbstract;

  constructor(
    private userService: UserService,
    private http: HttpClientService
  ) {
      this.user = this.userService.getUser();
    }

  getTripsOfUser(filter: UserTripFilter): Observable<UserTrip[]> {
    this.user = this.userService.getUser();
    filter.secureIdUsuario = this.user.secure_id;
    return this.http.get( EndpointsApi.getTripsOfUser,  filter).pipe( map( res => {
      const ret = res as UserTrip[];
      return ret;
    }));
  }

  getServices(filter: ServicesFilter): Observable<Service[]> {
    filter.secureId = this.user.secure_id;
    return this.http.get( EndpointsApi.getServices,  filter).pipe( map( res => {
      const ret = res as Service[];
      return ret;
    }));
  }

  getServicesDetall(filter: UserAllServicesFilter): Observable<Service[]> {
    return this.http.get( EndpointsApi.getServicesDisponibles, filter ).pipe( map( res => {
      const ret = res as Service[];
      return ret;
    }));
  }

  getServiceStartingPoints( secureId: string ): Observable<StoppingPoint[]> {
    return this.http.getWithUrlParams(EndpointsApi.getServicesStartingPoints.replace('{secureId}', secureId)).pipe( map( res => {
      const ret = res as StoppingPoint[];
      ret.sort( ( a, b ) =>  a.id - b.id );
      return ret;
    }));
  }

  getServiceEndPoints( idService: string, idStartingPoint: string ): Observable<StoppingPoint[]> {
    const params: Paradas = new Paradas( idService, idStartingPoint);
    return this.http.post( EndpointsApi.getTripsEndOfUser,  params).pipe( map( res => {
      const ret = res as StoppingPoint[];
      ret.sort( ( a, b ) =>  a.id - b.id );
      return ret;
    }));
  }

  getServiceHoursAvailables( service: any ): Observable<RouteSchedule[]> {
    return this.http.post( EndpointsApi.getHoursService,  service).pipe( map( res => {
      return res;
    }));
  }

  getServiceFab( secureId: string ): Observable<void> {
    const favService: FavService = {
      secureIdServicio: secureId,
      secureIdUsuario: this.user.secure_id
    };
    return this.http.post( EndpointsApi.GetServicioFavorito, favService).pipe( map( res => {
      return res;
    }));
  }

  getTriptic( secureId: string ): Observable<string> {
    return this.http.getWithUrlParams(EndpointsApi.GetTriptic.replace('{secureId}', secureId)).pipe( map( res => {
      return res;
    }));
  }
  getListTriptic( secureId: string ): Observable<FicheroDto[]> {
    return this.http.getWithUrlParams(EndpointsApi.GetListTriptic.replace('{secureId}', secureId)).pipe( map( res => {
      return res;
    }));
  }

  updateServiceFab( secureId: string ): Observable<void> {
    const favService: FavService = {
      secureIdServicio: secureId,
      secureIdUsuario: this.user.secure_id
    };

    return this.http.post( EndpointsApi.SetFavTrayecto, favService).pipe( map( res => {
      return res;
    }));
  }
 
  solCancelUserTrip( tripId: number ): Observable<void> {
    const userCancelDataDto =  {
      tripId: tripId.toString(),
      secureIdUsuario: this.user.secure_id
    };

    return this.http.delete( EndpointsApi.solCancelUserTrip, userCancelDataDto
      ).pipe( map( res => {
      return res;
    }));
  }

  solCancelUserSolicitud( idSolicitud: string ): Observable<void> {
    const userCancelDataSolDto =  {
      idSol: idSolicitud,
      secureIdUsuario: this.user.secure_id
    };

    return this.http.post( EndpointsApi.solCancelUserSolicitud, userCancelDataSolDto
      ).pipe( map( res => {
      return res;
    }));
  }

  // TODO revisar model entrada i sortida. No existeix al back.
  getServicePrice( service: any ): Observable<any> {
    return this.http.post( EndpointsApi.getPriceService,  service).pipe( map( res => {
      return res;
    }));
  }

  solService( service: any ): Observable<void> {
    return this.http.post( EndpointsApi.solicitateTrip,  service).pipe( map( res => {
      return res;
    }));
  }

  solLateUserTrip( tripId: number ): Observable<void> {
    const ret: Observable<void> = new Observable(observer => {
      observer.next();
    });
    return ret;
  }

  getCaracteristicasTaxi(): Observable<CaracteristicasTaxi[]> {
    return this.http.get( EndpointsApi.GetCaracteristicasTaxi ).pipe( map( res => {
      return res;
    }));
  }

  getCaracteristicasTrayecto(): Observable<CaracteristicasTrayecto[]> {
    return this.http.get( EndpointsApi.GetCaracteristicasTrayecto ).pipe( map( res => {
      return res;
    }));
  }
}
