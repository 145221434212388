export enum UserTripState {
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    ACCEPTED = 'ACCEPTED',
    FINISH = 'FINISH'
}

export interface UserTrip {
    id: number;
    idHistoric: number;
    idSolicitud: number;
    name: string;
    origin: string;
    destination: string;
    timeStart: string;
    fechaInicio: Date;
    timeEnd: string;
    timeSolicitud: string;
    price: number;
    state: UserTripState;
    taxista: string;
    telefon: string;
}
