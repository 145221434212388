
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { EndpointsApi } from '../config/endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { Institution } from '../models';
import { StorageKeys } from '../config/storage';

@Injectable({
    providedIn: 'root'
})
export class InstitutionService {

    private currentInstitution: Institution = this.storageService.memoryStorage._INSTITUTION;

    constructor(
        private http: HttpClientService,
        private storageService: StorageService
    ) { }

    getInstitutions(): Observable<Institution[]> {
        return this.http.get( EndpointsApi.getInstitutions );
    }

    setCurrentInstitution(i: Institution): void {
        this.currentInstitution = i;
        this.storageService.set(StorageKeys.institution, this.currentInstitution);
    }

    getTextApp(): Observable<any> {
        return this.http.getWithUrlParams( EndpointsApi.getLegalText );
    }
    getLegalTextApp(): Observable<any> {
        return this.http.getWithUrlParams( EndpointsApi.getLegalText );
    }

    getFilePrivacy(): Observable<any> {
        return this.http.getWithUrlParams( EndpointsApi.getFilePrivacy );
    }
}
