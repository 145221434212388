import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private navController: NavController,
        private userService: UserService,
        private platform: Platform
    ) { }


    canActivate(): boolean | Promise<boolean> | Observable<boolean> {
        if (this.userService.isUserLogged()) {
            return true;
        } else {
            this.navController.navigateRoot('/login');
            return false;
        }
    }


}
