import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderLogoComponent } from './header-logo.component';

@NgModule({
  declarations: [HeaderLogoComponent],
  imports: [
    CommonModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    HeaderLogoComponent
  ]
})
export class HeaderLogoModule { }
